import { styled } from "@mui/material/styles";
import { Card, Tabs, Tab } from "@mui/material";
import { useColors } from "../../Context/ColorsContext";
import { MediaQuerys } from "../../styles/GlobalStyle";

const { mobile, tablet, desktop } =  MediaQuerys;

export const SectionPageTitle = styled('section')(() => ({
    display:'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width:'100%',
}))

export const CustomCard = styled(Card)(() => ({
    height: '100%',
    // height: 'calc(100vh - 100px)',
    // display: 'flex',
    // flexDirection: 'column',
    // maxHeight: '534px',
    width: '100%',
    borderRadius: 6,
    boxShadow: 'rgb(61 71 82 / 20%) 0px 4px 8px',
    padding: 0,
    overflow: 'auto',
      '&::-webkit-scrollbar': {
        width: 5,
        height: 5
    },
}))

export const CustomTabs = styled(Tabs)(() => {
    const { colors } = useColors();
    return {
        padding: 0,
        margin: 0,
        '&.MuiButtonBase-root-MuiTab-root .Mui-selected': {
            color: `${colors.buttonActionColor} !important`
        },
        '.Mui-selected': {
            color: `${colors.buttonActionColor} !important`
        }
    }
})

export const ItemTab = styled(Tab)(() => {
    return {
        [desktop]: {
            fontSize: '0.80rem'
        }
    }
})

export const ContainerContent = styled('div')(() => {
    return {
        display: 'flex',
        flexDirection: 'row',
        marginTop: '10px',
        width: '100%',
        gap: '0.75rem',
        [tablet]: {
            flexDirection: 'column'
        },
        [mobile]: {
            flexDirection: 'column'
        }
    }
})


export const ContainerLeft = styled('div')(() => {
    return {
        display: 'flex',
        width: '73%',
        gap: '0.70rem',
        flexDirection: 'column',
        [desktop]: {
            width: '70%',
        },
        [tablet]: {
            width: '100%',
            flexDirection: 'row'
        },
        [mobile]: {
            flexDirection: 'column',
            width: '100%',
        }
    }
})

export const ContainerRight = styled('div')(() => {
    return {
        width: '27%',
        height: '100%',
        [desktop]: {
           width: '30%',
        },
        [tablet]: {
            width: '100%',
        },
        [mobile]: {
            width: '100%',
        }
    }
})

export const IconArea = styled('div')(() => {
    const { colors } = useColors();
    return {
        background: colors.detailTextColor,
        color: colors.white,
        width: 18,
        height: 18,
        fontSize: 12,
        fontWeight: 'bold',
        borderRadius: 20,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [desktop]: {
            width: '15px !important',
            height: '15px !important',
            fontSize: '11px !important',
        }
    }
})

export const DetailInfo = styled('div')(() => {
    return {
        width: '100%',
    }
})
