export const pdfTableStyleDetail = () => {
  return `
      .pdf-table-style table > tbody > tr > td, table > thead > tr > th {
        min-width: 50px !important;
        max-width: 80px !important;
      }
      
      .pdf-table-style table thead tr th {
        font-size: 11px !important;
        padding: 4px !important;
        min-width: auto !important; 
      }
      
      .pdf-table-style table tbody tr td {
        font-size: 11px !important;
        padding: 3px !important;
        height: 100%;
      }
      
      .pdf-table-style table tbody tr td div {
        min-width: 50px !important;
        // max-width: 60px !important;
      }
      
      .pdf-table-style table tbody tr td div .formio-component-htmlelement p {
        min-width: 50px !important;
        max-width: 100px !important;
      }
      
      .pdf-table-style table tbody tr td .input-group-append {
        display: none;
      }
      
      .pdf-table-style table tbody tr td input {
        font-size: 11px !important;
        padding: 4px !important;
      }
      
      .pdf-table-style table tbody tr td .formio-component-textarea {
        font-size: 11px !important;
      }
      
      .pdf-table-style table tbody tr td .formio-component-textarea div {
        font-size: 11px !important;
      }
      
      .pdf-table-style table tbody tr td .formio-component-textarea div .mb-3 {
        margin-bottom: 0px !important;
      }
      
      .pdf-table-style table tbody tr td .formio-component-textarea div .card-body {
        padding: 4px 2px;
      }
      
      .pdf-table-style table tbody tr td .formio-component-textarea div textarea {
        padding: 4px 2px;
        font-size: 11px;
      }
      
      .pdf-table-style table tbody tr td .formio-component-textarea div .card-body div .formio-editor-read-only-content {
        padding: 8px 2px;
      }
      
      .pdf-table-style table tbody tr td .formio-component-select {
        min-width: 50px !important;
        max-width: 85px !important;
      }
      
      .pdf-table-style table tbody tr td .formio-component-select .formio-choices .dropdown {
        padding: 2px !important;
      }
      
      .pdf-table-style table tbody tr td .formio-component-select .formio-choices .dropdown .choices__list--multiple .choices__item {
        font-size: 11px !important;
        padding: 2px !important;
      }
      
      .pdf-table-style table tbody tr td .formio-component-select .formio-choices .dropdown .choices__item.choices__item--selectable {
        height: 100% !important;
        padding-right: 8px !important;
      }
  
      .matriz-riesgos-style{
        display: none !important;
      }

    .custom-table.pdf-table-style,
      .custom-table.table-minute-book {
      max-height: none !important;
    }

    .custom-table.pdf-table-style > table > tbody,
    .custom-table.table-minute-book > table > tbody {
      overflow-y: none !important; 
    }
  
  }`
}