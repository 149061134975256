import { styled } from "@mui/material/styles";
import { Card, Typography, Button, Box, Grid } from "@mui/material";
import { useColors } from "../../../Context/ColorsContext";
import { MediaQuerys } from "../../../styles/GlobalStyle";

const { mobile, tablet, desktop } = MediaQuerys;

export const CustomCard = styled(Card)(() => ({
    // minHeight: 430,
    // maxHeight: 'calc(100vh - 100px)',
    width: '100%',
    borderRadius: 6,
    boxShadow: 'rgb(61 71 82 / 20%) 0px 4px 8px',
    padding: '1rem',
    [tablet]: {
        height: '100%',
    }
}))

export const Title = styled(Typography)(() => {
    const { colors } = useColors();
    return {
        color: colors.detailTextColor,
        [desktop]: {
            fontSize: '1.1rem',
        },
        [tablet]: {
            fontSize: '0.90rem',
        }
    }
})

export const Subtitle = styled(Typography)(() => {
    const { colors } = useColors();
    return {
        color: colors.detailTextColor,
        [tablet]: {
            fontSize: '0.90rem',
        }
    }
})

export const Description = styled(Typography)(() => {
    const { colors } = useColors();
    return {
        color: colors.blackFont,
        [tablet]: {
            fontSize: '0.80rem'
        }
    }
})

export const ButtonCustom = styled(Button)(({theme}) => {
    const { colors } = useColors();
    return {
        width: '100%',
        height: 40,
        padding: 8,
        fontSize: 11,
        lineHeight: 1.4,
        background: colors.buttonActionColor,
        color: colors.white,
        "&:hover": {
            backgroundColor: colors.buttonActionColor,
            opacity: 0.9,
            color: colors.white,
        },
        [desktop]: {
            fontSize: '0.55rem',
            height: 30,

        },
        [tablet]: {
            height: 30
        }
    }
})

export const BoxCustom = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}))

export const Figure = styled('figure')(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '30px',
    width: '30px',
    minWidth: '30px',
    borderRadius: '50%',
    marginLeft: '1.2rem !important',
    padding: '1rem',
}))

export const AreaIcon = styled('span')(() => {
    const { colors } = useColors();
    return {
        background: colors.orange,
        borderRadius: '50%',
        height: '1.2rem',
        width: '1.2rem',
        lineHeight: '1.2rem',
        display: 'inline-block',
        textAlign: 'center',
        color: colors.white,
        fontSize: '0.9rem'
    }
})

export const BoxModal = styled(Box)(() => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
}))

export const ButtonsContainer = styled(Grid)(({theme}) => ({
    [theme.breakpoints.up('xs')]: {
        marginTop: 2
    },
    [theme.breakpoints.up('xl')]: {
        marginTop: 30
    },
}))


export const ContainerGroupButtons = styled('div')(({theme}) => {
    return {
        display: 'flex',
        marginTop: '10px',
        width: '100%',
        flexDirection: 'column',
        gap: '0.50rem',
    }
})

export const ContainerButtons = styled('div')(() => {
    return {
        width: '100%',
        [tablet]: {
            width: '100%'
        }
    }
})


export const ContainerButtonsStatus = styled('div')(() => {
    return {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
    }
})

export const ContainerStatus = styled('div')(() => {
    return {
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-start',
    }
})

export const ContainerWorkflows = styled('div')(() => {
    return {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'flex-start',
        alignItems: 'center',
    }
})

export const Container = styled('div')(() => {
    return {
        width: 'auto',
    }
})

export const ContainerButtonsDown = styled('div')(() => {
    return {
        display: 'flex',
        flexDirection: 'row',
        gap: '0.80rem',
        [desktop]: {
            flexDirection: 'column',
        }
    }
})

export const ContainerContent = styled('div')(() => {
    return {
        // display: 'flex',
        // gap: '0.60rem',
        width: '100%',
        height: '100%'
    }
})
