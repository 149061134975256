// Components MUI
import Typography from '@mui/material/Typography';
import { Tooltip } from '@mui/material';

// Datepicker
import 'dayjs/locale/es';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from "dayjs";

import { useTranslation } from "react-i18next";

// Components
import { FormButton } from '../../../../pages/styles';
import {
    Container,
    Header,
    Body,
    Footer,
    BoxContainer,
    SectionRight,
    ContainerDates,
    ContainerGroup,
    ContainerInputsDate,
    CleanIcon,
} from './style';
import { ClearIconButton } from '../FiltersWorkflowModalCard/style';


export const RevalidationsExcelDownload = ({datesDownloadValid, setOpendownloadValidations, handleChangeDateDownload, handleDownloadExcel, errorsDate, handleClearDates }) => {

  const { t } = useTranslation();
  dayjs.locale('es');



    return (
        <Container>
            <Header>Descargar revalidaciones</Header>
            <Body>
                <BoxContainer>
                    <ContainerDates>
                        <Typography variant="caption" sx={{ marginBottom: '20px' }}>
                            {t('common.downloadExcel')}
                        </Typography>
                        <ContainerGroup>
                            {/* Input desde */}
                            <ContainerInputsDate errorstart={errorsDate.startDate}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DatePicker']}>
                                        <DatePicker
                                            label={t("common.initDate")}
                                            value={dayjs(datesDownloadValid?.startDate)}
                                            minDate={dayjs('2020-03-20')}
                                            maxDate={dayjs()}
                                            onChange={e => handleChangeDateDownload(e || "", "startDate")}
                                            slotProps={{ textField: { size: 'small', error: errorsDate.startDate, required: errorsDate.startDate } }}
                                            format="DD/MM/YYYY"
                                        />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </ContainerInputsDate>
                            {/* Input hasta */}
                            <ContainerInputsDate errorend={errorsDate.endDate}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DatePicker']}>
                                        <DatePicker
                                            label={t("common.lastDate")}
                                            value={dayjs(datesDownloadValid?.endDate)}
                                            minDate={dayjs(datesDownloadValid?.startDate)}
                                            maxDate={dayjs()}
                                            onChange={(e) => handleChangeDateDownload(e || "", "endDate")}
                                            slotProps={{ textField: { size: 'small', error: errorsDate.endDate, required: errorsDate.endDate } }}
                                            format="DD/MM/YYYY"
                                        />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </ContainerInputsDate>

                            <Tooltip>
                                <ClearIconButton
                                    size="small"
                                    aria-label="plot"
                                    onClick={handleClearDates}
                                >
                                    <CleanIcon/>
                                </ClearIconButton>

                            </Tooltip>
                        </ContainerGroup>
                    </ContainerDates>
                </BoxContainer>
            </Body>
            <Footer>
                <SectionRight>
                    <FormButton onClick={() => setOpendownloadValidations(false)} type='close' variant="outlined">{t('common.cancel')}</FormButton>
                    <FormButton onClick={handleDownloadExcel}>{t('common.download')}</FormButton>
                </SectionRight>
            </Footer>
        </Container>
    )
}
