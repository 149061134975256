import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Button } from "@mui/material";
import { Container, ContainerBarSearch, ContainerButtonDownload, SectionPageLeft, SectionPageTitle } from "../styles";
import { IconAction, NewIcon, ContainersButtonsGroup } from "./styles";
import { PageTitle } from "../../components/PageTitle";
import { SearchBar } from "../../components/atoms/searchBars/SearchBar";
import TableWorkflowManagement from "../../components/atoms/tables/TableWorkflowManagement";
import { WorkflowApi } from "../../services/WorkflowApi";
import { toast } from "react-toastify";
import Tooltip from '@mui/material/Tooltip';
import Router from "../../router";
import { FormatDate } from "../../utils/formatDate";
import { ManageHistory, CloudDownloadOutlined } from "@mui/icons-material";
import { CheckResponse } from "../../utils/checkResponse";
import { useTranslation } from "react-i18next";
import { ColorsContext } from "../../Context/ColorsContext"


import { ShowDialog } from "../../components/ShowDialog";

//?? Export Excel
import { ExportExcel } from './../../utils/exportExcel';

export const WorkflowManagement = (props) => {
  const { t } = useTranslation();
  const workflowApi = new WorkflowApi();
  const { colors } = React.useContext(ColorsContext);

  const params = useParams();
  const navigate = useNavigate();
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const { format } = new FormatDate();
  const { check } = new CheckResponse();

  const [filteredRequest, setFilteredRequest] = React.useState([]);


  const [orders, setOrders] = React.useState([]);
  const [dialog, setDialog] = useState({
    status: false,
		title: '',
		message: '',
		type: null
  });

  useEffect(() => {
    setLoading(true);

    if (props.isDemo) {
      navigate("/");
    } else {
      loadData();
    }

    setLoading(false);
  }, [props.isDemo, params, setData]);

  async function loadData() {
    let response = await workflowApi.list({});
    if (response.status === 200) {
      setData(response.data);
    }
    return response.data || []
  }

  async function handleDatesFilter(e) {
    e.preventDefault();
    setLoading(true);
    setDatesForm({ ...datesForm, initialDate, finalDate });

    let response = await loadData();
    let newData = response.filter((el) => {
      return (
        moment(el.updatedAt).format("YYYY-MM-DD") >= initialDate &&
        moment(el.updatedAt).format("YYYY-MM-DD") <= finalDate
      );
    });
    setData(newData);
    setLoading(false);
    e.target.reset();
  }

  async function handleClearDate() {
    setFinalDate(moment(new Date()).format("YYYY-MM-DD"));
    setInitialDate(
      moment(finalDate).subtract(1, "months").format("YYYY-MM-DD")
    );

    setLoading(true);
    await loadData();
    setLoading(false);
  }

  async function handleCreateWorkflow() {
    navigate(Router.appWorkflowManagementDetail, { state: { row: {} } });
  }

  async function handleDelete(data) {
    setLoading(true);
    const { name } = data;
    let response = await workflowApi.delete(data);
    let result = check(response);

    if (result.status) {
      setDialog({
        status: true,
        title: t("workflowManagement.messages.deleteWorkflow.title"),
        message: t("workflowManagement.messages.deleteWorkflow.deleteFlow", { workflow: name }),
        type: null,
      });
      await loadData();
    } else {
      toast.error(() => (
        <div>
          {t("workflowManagement.messages.deleteWorkflow.error")}
          <br />
          {result.errors}
        </div>
      ));
    }
    setLoading(false);
  }

  async function handleOrderUp(index, order) {
    let dataArray = [...data];
    let upElement = dataArray[index - 1];
    let actualElement = dataArray.splice(index, 1)[0];

    actualElement.order = upElement.order;
    upElement.order = order;

    let item1 = {
      workflowId: actualElement.id,
      newOrder: actualElement.order,
    };

    let item2 = {
      workflowId: upElement.id,
      newOrder: upElement.order,
    };

    let orders = [];

    orders.push(item1);
    orders.push(item2);

    addOrder(orders);

    dataArray.splice(index - 1, 0, actualElement);
    setData(dataArray);
  }

  async function handleOrderDown(index, order) {
    let dataArray = [...data];
    let upElement = dataArray[index + 1];
    let actualElement = dataArray.splice(index, 1)[0];

    actualElement.order = upElement.order;
    upElement.order = order;

    let item1 = {
      workflowId: actualElement.id,
      newOrder: actualElement.order,
    };

    let item2 = {
      workflowId: upElement.id,
      newOrder: upElement.order,
    };

    let orders = [];

    orders.push(item1);
    orders.push(item2);

    addOrder(orders);

    dataArray.splice(index + 1, 0, actualElement);
    setData(dataArray);
  }

  function addOrder(ordersChange = []) {
    let ordersArray = [...orders];

    ordersChange.forEach((order) => {
      let item = {
        workflowId: order.workflowId,
        newOrder: order.newOrder,
      };

      if (ordersArray.length == 0) {
        ordersArray.push(item);
      } else {
        let index = ordersArray.findIndex(function (element, index) {
          if (element.workflowId == order.workflowId) {
            return index;
          }
        });

        if (index == -1) {
          ordersArray.push(item);
        } else {
          ordersArray.splice(index, 1, item);
        }
      }
    });
    setOrders(ordersArray);
  }

  async function handleSaveOrder() {
    let response = await workflowApi.changeOrder(orders);
    let result = check(response);

    if (result.status) {
      toast.success(t("workflowManagement.messages.changeOrder.title"));
    } else {
      toast.error(() => (
        <div>
          {t("workflowManagement.messages.changeOrder.error")}
          <br />
          {result.errors}
        </div>
      ));
    }
    await loadData();
  }

  const handleOpenDialog = async (row) => {
    setDialog({
      status: true,
      title: t("forms.formManagement.messages.deletedForm.confirm"),
      message: t("workflowManagement.messages.deleteWorkflow.confirmMessage", {
        workflow: row.name,
      }),
      note: t("workflowManagement.messages.deleteWorkflow.note"),
      type: "confirmation",
      id: row.id,
      data: row,
    });
  }

  const handleCloseDialog = async (value) => {
    if( value ) {
      setDialog({ ...dialog, status: false });
      await handleDelete(dialog.data);
    }else {
      setDialog({ status: false });
    }
  }

  //?? Method Download Excel
  const handleDownloadExcel = () => {

    let data = filteredRequest.map((item) => ({
      [t('workflowManagement.table.order')]: item.order,
      [t('workflowManagement.table.number')]: item.id,
      [t('workflowManagement.table.name')]: item.name,
      [t('workflowManagement.table.prefix')]: item.prefix,
      [t('workflowManagement.table.integrationKey')]: item.integrationKey,
      [t('workflowManagement.table.lastUpdate')]: format({ date: item.updatedAt, format: true }).toString(),
    }))

    ExportExcel({ apiData: data, fileName: t('workflowManagement.title') });

  }

  return (
    <Container open={props.open}>
      <SectionPageTitle>
        <SectionPageLeft>
          <PageTitle
            title={t("workflowManagement.title")}
            icon={<ManageHistory />}
          />
          <ContainerBarSearch>
						<SearchBar 
							rows={data} 
							setFilteredRequest={setFilteredRequest}
							filterText={t('common.searchBars.workflowManagement')}
							filterKeys={['id', 'name', 'prefix']}
						/>
          </ContainerBarSearch>
        </SectionPageLeft>


        <ContainersButtonsGroup>
            <Tooltip title={t('workflowManagement.table.createNew')}>
              <IconAction size="medium" onClick={handleCreateWorkflow}>
                <NewIcon />
              </IconAction>
            </Tooltip>

          <ContainerButtonDownload>
            <Button
              variant="contained"
              endIcon={<CloudDownloadOutlined />}
              sx={{
                height: '100%',
								width: '100%',
								padding: "0 20px",
								backgroundColor: colors.buttonActionColor,
								color: colors.white,
								"&:hover": {
									backgroundColor: colors.buttonActionColor,
									opacity: 0.9,
									color: colors.white
								}
              }}
              aria-label="download"
              onClick={handleDownloadExcel}
            >
              {t('common.download')}
            </Button>
          </ContainerButtonDownload>
        </ContainersButtonsGroup>
      </SectionPageTitle>

      <TableWorkflowManagement
        filteredRequest={filteredRequest}
        rows={data}
        loading={loading}
        handleCreateWorkflow={handleCreateWorkflow}
        handleDelete={handleOpenDialog}
        handleOrderUp={handleOrderUp}
        handleOrderDown={handleOrderDown}
        handleSaveOrder={handleSaveOrder}
      />

      <ShowDialog
        openDialog={dialog.status}
        dialog={dialog}
        handleCloseDialog={handleCloseDialog}
      />
    </Container>
  );
};
