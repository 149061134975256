import React from 'react'

// Styles
import {
  Container,
  Body,
  ContainerInfo,
} from '../../styles'


// Utils

// Permissions
import { ContractScopeContainer } from '../../../../../../containers/ContractScopeContainer';

export const InternalRoleWithContract = ({
  data,
  handleChangeStepTwo,
  form,
  setForm,
  errors,
  setErrors,
  isSupervisor,
  showCheckSupervisor
}) => {

  React.useEffect(() => {
  }, [data])

  return (
    <ContractScopeContainer
      data={data}
      handleChangeStepTwo={handleChangeStepTwo}
      form={form}
      setForm={setForm}
      errors={errors}
      setErrors={setErrors}
      isSupervisor={isSupervisor}
      showCheckSupervisor={showCheckSupervisor}
    />
  )
}
