import React, { useEffect, useState, useRef } from "react";

// Components
import moment from "moment";
import { Modal, ButtonGroup, Tooltip, Button, Menu, MenuItem } from "@mui/material";
import { toast } from "react-toastify";

// Custom components
import { PageTitle } from "../../components/PageTitle";
// import { SearchBar } from "../../components/atoms/searchBars/SearchBar";
import { SearchBarCompanyWorkers } from "../../components/atoms/searchBars/SearchBarCompanyWorkers";
import TableCompanyWorkers from "../../components/atoms/tables/TableCompanyWorkers";
import { ShowDialog } from "../../components/ShowDialog";
import { BackdropLoading } from "../../components/BackdropLoading";
import { CompanyWorkerModalCard } from "../../components/atoms/modalCards/CompanyWorkerModalCard";

// Services
import { CompanyWorkerApi } from "../../services/CompanyWorkerApi";
import { WorkerCertificateApi } from "../../services/WorkerCertificateApi";

// Styles
import { BoxModal, Container, ContainerBarSearch, ContainerButtonDownload, ContainersButtonsGroup, SectionPageLeft, SectionPageTitle } from "../styles";
import { ContainerButtonSmall, ContainerButtonCreate, DownloadButton, TemplateButton, CustomBtn, ButtonsGroup, CustomMenuItem } from "./styles";

// Icons
import { CloudDownloadOutlined, RecentActors } from "@mui/icons-material";

// Utils
import { useTranslation } from 'react-i18next'
import { CheckResponse } from "../../utils/checkResponse";
import { validateFiles, returnStringCertificateNames } from "../../utils/commonFunctions";

// Hooks
import { useDownloadExcel } from "../../Hooks/useDownloadExcel";

// Permissions
import { useAbility } from '@casl/react';
import { AbilityContext } from '../../Context/PermissionsContext'

export const CompanyWorker = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const fileInputRef = useRef(null);

  const handleUploadClick = () => {
    // Handle upload action
    fileInputRef.current.click();
  };

  const handleDownloadClick = () => {
    // Handle download action
    handleDownloadTemplate();
    handleClose();
  };

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { t } = useTranslation();
  const ability = useAbility(AbilityContext);

  const companyWorkerApi = new CompanyWorkerApi();
  const workerCertificateApi = new WorkerCertificateApi();
  const { check } = new CheckResponse();

  const [data, setData] = useState([]);
  const [dataCertificates, setDataCertificates] = useState([]);
  const [loading, setLoading] = useState(false);

  const [filteredRequest, setFilteredRequest] = useState([]);
  const [finalDate, setFinalDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [initialDate, setInitialDate] = useState(
    moment(finalDate).subtract(1, "months").format("YYYY-MM-DD")
  );
  const [dialog, setDialog] = useState({
    status: false,
    title: "",
    message: "",
    type: null,
  });

  const firstLoad = React.useRef(true);
  const clearFilter = React.useRef(false);
  const searchText = React.useRef("");
  const pageRef = React.useRef({
    page: 0,
    pageSize: 10,
    rowsCount: 0,
    fullRowsCount: 0
  });

  const [openCompanyWorkerModal, setOpenCompanyWorkerModal] = useState(false);
  const [detail, setDetail] = useState({});

  const [headers, setHeaders] = useState([]);

  useEffect(() => {
    async function call() {
      firstLoad.current = true;
      await handleClearFilters(true);
    }
    call();
  }, []);

  const fieldsConfig = {
    [t('worker.table.id')]: 'workerId',
    [t('worker.table.identification')]: 'identification',
    [t('worker.table.name')]: 'name',
    [t('worker.table.lastName')]: 'lastName',
    [t('worker.table.eps')]: 'eps',
    [t('worker.table.arl')]: 'arl',
    [t('worker.table.certificates')]: (item) => returnStringCertificateNames(item.certificates),
    [t('worker.table.company')]: (item) => item.company.name,
  };

  const { handleDownloadExcelList } = useDownloadExcel(filteredRequest, fieldsConfig, t('worker.title'));

  const returnHeaders = (data) => {
    let heads = [
      {
        id: "identification",
        label: "worker.table.identification",
        isRender: true,
        center: false,
        sortArrow: false,
        sticky: true,
        direction: 'left',
      },
      {
        id: "name",
        label: "worker.table.name",
        isRender: true,
        center: false,
        sortArrow: false
      },
      {
        id: "lastName",
        label: "worker.table.lastName",
        isRender: true,
        center: false,
        sortArrow: false
      },
      {
        id: "eps",
        label: "worker.table.eps",
        isRender: true,
        center: false,
        sortArrow: false
      },
      {
        id: "arl",
        label: "worker.table.arl",
        isRender: true,
        center: false,
        sortArrow: false
      },
      {
        id: "certificate",
        label: "worker.table.certificates",
        type: "certificates",
        center: false,
        sortArrow: false
      },
      {
        id: "company",
        label: "worker.table.company",
        center: false,
        format: (value) => value.company?.name,
        customSort: "company?.name",
        sortArrow: false
      }
    ];

    let newActions = [];
    if (ability.can("UpdateWorkers")) {
      newActions.push('edit');
    }
    if (ability.can("DeleteWorkers")) {
      newActions.push('delete');
    }

    if (newActions.length > 0) {
      heads.push(addActionsRow(newActions))
    }

    setHeaders(heads);
  }

  function addActionsRow(actions) {
    return {
      id: "actions",
      label: "common.actions.title",
      type: "actions",
      center: false,
      sortArrow: false,
      sticky: true,
      direction: 'right',
      keys: actions,
    }
  }

  async function loadData() {
    setLoading(true);

    let respCertificate = await workerCertificateApi.list();
    if (respCertificate.status === 200) {
      setDataCertificates(respCertificate.data)
    }

    let response = await companyWorkerApi.list({
      isEnabled: true,
      currentPage: pageRef.current.page + 1,
      pageSize: pageRef.current.pageSize,
      filter: searchText.current
    });
    
    if (response.status === 200) {
      let dataResp = response.data;

      pageRef.current.rowsCount = dataResp.rowsCount;
      pageRef.current.fullRowsCount = dataResp.rowsCount;

      let arrayData = await formatResponse(dataResp)
      setLoading(false);
      firstLoad.current = false;

      return { hasData: true, data: arrayData };
    }
    
    setLoading(false);
    return { hasData: false, data: [] };
  }

  function handleEdit(row) {
    setOpenCompanyWorkerModal(true)
    setDetail(row)
  }

  function handleCreate() {
    setOpenCompanyWorkerModal(true)
    setDetail({})
  }

  async function handleDownloadTemplate() {
    setLoading(true);

    let response = await companyWorkerApi.template();
    let result = check(response)

    if (result.status) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'template.xlsx');
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    }
    else {
      toast.error(() =>
        <div>{t('worker.messages.template.error')}<br />
          {result.errors}
        </div>
      );
    }
    setLoading(false);
  }

  async function handleSaveUploadFile(event) {
    let file = event?.target.files[0]

    if (file != null) {
      setLoading(true)
      let resultCheck = await validateFiles([file])
      if (!resultCheck) {
        setLoading(false)
        return
      }

      setLoading(true);
      let formData = new FormData()
      formData.append('file', file)

      let response = await companyWorkerApi.upload(formData);
      let result = check(response)

      if (result.status) {
        setDialog({
          status: true,
          title: t('worker.messages.save.title'),
          message: t('worker.messages.save.message'),
          type: null,
        });
        await loadData();
      }
      else {
        toast.error(() =>
          <div>{t('worker.messages.save.error')}<br />
            {result.errors}
          </div>
        );
      }
      setLoading(false);

    }
    // Restablece el valor del input de archivo a null
    event.target.value = null;
  }

  async function handleSaveEdit(data) {
    const certificateIds = data.certificates.reduce((accumulator, currentValue) => {
      if (currentValue.status === true) {
        accumulator.push(currentValue.id);
      }
      return accumulator;
    }, []);
    let formData = { ...data, certificates: certificateIds }

    setLoading(true);
    let response = null
    if (formData.id) response = await companyWorkerApi.update(formData.id, formData);
    else response = await companyWorkerApi.create(formData);

    let result = check(response)

    if (result.status) {
      setOpenCompanyWorkerModal(false);
      setDialog({
        status: true,
        title: t('worker.messages.save.title'),
        message: t('worker.messages.save.message'),
        type: null,
      });
      await loadData();
    }
    else {
      toast.error(() =>
        <div>{t('worker.messages.save.error')}<br />
          {result.errors}
        </div>
      );
    }
    setLoading(false);
  }

  async function handleDelete() {
    if (!dialog.row.workerId) {
      return
    }

    setLoading(true);
    let response = await companyWorkerApi.delete(dialog.row.workerId);
    let result = check(response)

    if (result.status) {
      handleCloseModalDelete();
      setDialog({
        status: true,
        title: t('worker.messages.delete.title'),
        message: t('worker.messages.delete.message'),
        type: null,
      });
      await loadData();
    }
    else {
      toast.error(() =>
        <div>{t('worker.messages.delete.error')}<br />
          {result.errors}
        </div>
      );
    }
    setLoading(false);
  }

  async function handleOpenModalDelete(row) {
    setDialog({
      status: true,
      title: t('worker.messages.delete.confirm'),
      message: t('worker.messages.delete.confirmMessage', { worker: `${row.name} ${row.lastName}` }),
      type: "confirmation",
      row: row
    });
  }

  async function handleCloseModalDelete(confirmDelete) {
    if (confirmDelete) {
      setDialog({ ...dialog, status: false });
      await handleDelete()
    } else {
      setDialog({ status: false });
    }
  }

  async function handleChangePage(page, pageSize, rowsCount, isFilter) {
    pageRef.current.page = page;
    pageRef.current.pageSize = pageSize || 10;
    pageRef.current.rowsCount = rowsCount || pageRef.current.fullRowsCount;
    if (!isFilter) return await loadData();
    return []
  };

  const handleChangeFilter = async() => {
    pageRef.current.page = 0;
    firstLoad.current = true;
    await loadData();
  };

  async function handleFilters(e) {
    e?.preventDefault();
    firstLoad.current = true;
    await handleChangePage(0, pageRef.current.pageSize);
  }

  const chunk = (arr, size) =>
    Array.from({ length: Math.ceil(arr.length / size) }, (_, i) =>
      arr.slice(i * size, i * size + size)
  );

  async function formatResponse(currentData) {
    let arrayData = [];

    const arrayConWorkerId = currentData.results.map(({ id, ...attrs }) => ({
      workerId: id, // Renombramos id a workerId
      ...attrs      // Conservamos el resto de las propiedades
    }));

    if (data.length === 0 || firstLoad.current) {
      arrayData = arrayConWorkerId;
    }
    else {
      // Dividir filteredRequest en bloques de datos de pageSize
      let newArray = chunk(data, pageRef.current.pageSize);

      // Validar si la data de currentpage ya existe en filteredRequest
      // Si no existe se agrega la nueva data a filteredRequest
      if (newArray[currentData.currentPage - 1] === undefined) {
        arrayData = [...data, ...arrayConWorkerId];
      }
      // Si ya existe se busca la posición y se reemplaza con la nueva data
      else {
        // Mantener el orden de las páginas anteriores y agregar los nuevos datos
        newArray[currentData.currentPage - 1] = arrayConWorkerId;
        
        // Aplana el array después de reemplazar la página correcta, pero respeta el orden original
        arrayData = [].concat(...newArray);
      }
    }
    
    if (firstLoad.current) {
      returnHeaders(arrayData);
    }

    setData(arrayData);
    setFilteredRequest(arrayData);
    return arrayData;
  }

  async function handleClearFilters(isChangeWorkflow) {
    setFilteredRequest([]);
    setData([]);
    firstLoad.current = true;
    let pageSize = isChangeWorkflow === true ? 10 : pageRef.current.pageSize
    pageRef.current = {page: 0, pageSize: pageSize, rowsCount: 0, fullRowsCount: 0};

    clearFilter.current = !clearFilter.current;
    await loadData();
  }

  return (
    <Container open={props.open}>
      <SectionPageTitle>
        <SectionPageLeft>
          <PageTitle title={t('worker.title')} icon={<RecentActors />} />
          <ContainerBarSearch>
            <SearchBarCompanyWorkers
              handleChangeFilter={handleChangeFilter} 
              handleFilters={handleFilters} 
              searchText={searchText} 
              clearFilter={clearFilter}
            />
          </ContainerBarSearch>
        </SectionPageLeft>

        <ContainersButtonsGroup twoContainers={true} separateTop={true}>
          <ButtonsGroup>

            <ContainerButtonDownload>
              <DownloadButton
                variant="contained"
                endIcon={<CloudDownloadOutlined />}
                aria-label="download"
                onClick={handleDownloadExcelList}
              >
                {t('common.download')}
              </DownloadButton>
            </ContainerButtonDownload>

            <div>
              <CustomBtn
                variant="contained"
                onClick={handleOpen}
              >
                {t('worker.template')}
              </CustomBtn>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <CustomMenuItem onClick={handleDownloadClick}>
                  {t('worker.downloadTemplate')}
                </CustomMenuItem>
                <CustomMenuItem onClick={handleUploadClick}>
                  {t('worker.uploadFile')}
                  <input
                    type="file"
                    hidden
                    onChange={handleSaveUploadFile}
                    ref={fileInputRef}
                    accept=".xlsx"
                    onClick={(e) => e.stopPropagation()}
                  />
                </CustomMenuItem>
              </Menu>
            </div>
          </ButtonsGroup>

          {ability.can("CreateWorkers") &&
            <ContainerButtonCreate>
              <DownloadButton
                variant="contained"
                endIcon={<RecentActors />}
                aria-label="download"
                onClick={handleCreate}
              >
                {t('worker.modal.create')}
              </DownloadButton>
            </ContainerButtonCreate>
          }

        </ContainersButtonsGroup>
      </SectionPageTitle>

      <TableCompanyWorkers
        headers={headers}
        filteredRequest={filteredRequest}
        // rows={data}
        rows={data}
        // Manage date
        initialDate={initialDate}
        setInitialDate={setInitialDate}
        finalDate={finalDate}
        setFinalDate={setFinalDate}
        // Handle edit, delete, process
        handleEdit={handleEdit}
        handleDelete={handleOpenModalDelete}
        hideFilterDate={true}
        // Paginator data
        isCustom={true}
        onChangePage={handleChangePage}
        rowsCount={pageRef.current.rowsCount}
        currentPage={pageRef.current.page}
        pageSize={pageRef.current.pageSize}
      />

      <ShowDialog
        openDialog={dialog.status}
        dialog={dialog}
        handleCloseDialog={handleCloseModalDelete}
      />

      <Modal open={openCompanyWorkerModal}>
        <BoxModal>
          <CompanyWorkerModalCard
            data={detail}
            dataCertificates={dataCertificates}
            handleSave={handleSaveEdit}
            handleClose={() => setOpenCompanyWorkerModal(false)}
          />
        </BoxModal>
      </Modal>

      <BackdropLoading open={loading} />

    </Container>
  );
};