import React, { useEffect, useState } from 'react';

// Components
import Router from '../../../../router';
import { toast } from 'react-toastify';

// Custom components
import { BackdropLoading } from '../../../BackdropLoading';

// API
import { ContractApi } from '../../../../services/ContractApi';

// Styles
import { Container, Header, Body, Footer, SectionRight, Spacer, } from './styles'
import { FormButton } from '../../../../pages/styles';

// Utils
import { CheckResponse } from '../../../../utils/checkResponse';
import { useTranslation } from 'react-i18next';
import { TechnicalSupervisor } from '../../../molecules/forms/TechnicalSupervisor';
import { UrlToFile } from '../../../../utils/urlToFile';

export const TechnicalSupervisorModalCard = ({ data, handleSave, handleClose, titleHeader }) => {

    const contractApi = new ContractApi();
    const { check } = new CheckResponse();
    const { t } = useTranslation();

    const [plantList, setPlantList] = useState([])
    const users = React.useRef([])

    const [form, setForm] = useState({
        id: 0,
        contractor: '',
        contractorId: '',
        contractId: '',
        code: '',
        plants: [],
        contractsPlants: [],
        technicalSupervisors: [],
        attachments: []
    })
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        async function call() {
            setLoading(true)

            await getSupervisors()
            await getContractData(data?.id)

            setLoading(false)
        }
        call()
    }, [])

    function checkItems(array) {

        return array.map(item => ({
            ...item,
            hasEmptyPlants: item.plants.length === 0,
            errorEmptyPlants: item.plants.length === 0 ? t("system.validations.mandatory") : '',
            hasEmptyContractors: item.contractor === '',
            errorEmptyContractors: item.contractor === '' ? t("system.validations.mandatory") : ''
        }))

    }

    const validateForm = () => {
        let hasError = false;

        let array = form.technicalSupervisors
        if (array.length > 0 && array[0]?.id !== undefined) {
            array = checkItems(array)
            setForm({...form, technicalSupervisors: array})
    
            const hasEmptyPlants = array.some(item => item.hasEmptyPlants);
            const hasEmptyContractor = array.some(item => item.hasEmptyContractors);
    
            if (hasEmptyPlants || hasEmptyContractor) hasError = true;
        }

        return hasError;
    }

    async function getSupervisors() {
        let response = await contractApi.getTechnicalSupervisors()
        let result = check(response)

        if (result.status) {
            let supervisors = response.data?.map(item => {
                const contractor = `${item?.identification ?? ''} - ${item?.name ?? ''} ${item?.lastName ?? ''}`;
                return { ...item, contractor };
            }) || [];
            supervisors.sort((a, b) => a.contractor.localeCompare(b.contractor, undefined, { sensitivity: 'base' }));

            users.current = supervisors
        }
        else {
            toast.error(() => <>{result.errors}</>)
        }
    }

    async function getContractData(id) {
        let response = await contractApi.getSupervisorData(id);
        let result = check(response)
        if (result.status) {
            let data = response.data
            let supervisorsData;
            if (data.technicalSupervisors.length > 0) {
                supervisorsData = data.technicalSupervisors.map((item) => { return { ...item, contractor: `${item?.identification ?? ''} - ${item?.name ?? ''} ${item?.lastName ?? ''}` } })
            } else {
                supervisorsData = [...emptyRow()]
            }
            
            let arrFiles = supervisorsData.forEach((sup) => {
                sup.supervisorAttachments.forEach((el) => {
                    el.oldFile = true
                })
            }) || []
            
            setForm({
                ...form,
                id: data.contract.id,
                code: data.contract.code,
                contractor: "",
                plants: data.plants,
                contractorId: "",
                technicalSupervisors: supervisorsData,
                attachments: arrFiles
            })
            checkAvailableContractors(supervisorsData)
            setPlantList(data.plants)
        }
        else {
            toast.error(() => <>{result.errors}</>)
        }
    }

    const emptyRow = () => {
		return [
			{
                contractor: '',
                plants: [],
                supervisorAttachments: [],
                userType: 2
			}
		]
	}

    function handleAddRow() {
		let array = form.technicalSupervisors

        array = checkItems(array)

        const hasEmptyPlants = array.some(item => item.hasEmptyPlants);
        const hasEmptyContractor = array.some(item => item.hasEmptyContractors);

		if (!hasEmptyPlants && !hasEmptyContractor) array.push(...emptyRow())

		setForm({...form, technicalSupervisors: array})
	}

    function handleRemoveRow(index) {
		let array = form.technicalSupervisors
		array.splice(index, 1)

		if (array.length === 0) {
			array = emptyRow()
		}

		setForm({...form, technicalSupervisors: array})
		checkAvailableContractors(array)
	}

    function handleChange(name, value, idx) {
        let technicalSupervisors = [...form.technicalSupervisors]
        if (name === 'contractors'){
            // Se conservan las plantas asignadas previamente
            let tempPlants = technicalSupervisors[idx]?.plants ?? []
            technicalSupervisors[idx] = value;
            technicalSupervisors[idx].plants = tempPlants;
            technicalSupervisors[idx].supervisorAttachments = [];
        } else if (name === 'plants') {
            technicalSupervisors[idx]['plants'] = value;
        }

        setForm({...form, technicalSupervisors})
        checkAvailableContractors(technicalSupervisors)
    }

    function checkForm() {
        let invalidate = validateForm()
        if (invalidate) return

        handleSave(form)
    }

    function checkAvailableContractors(items) {
		let contractorUsers = [...users.current]
        let arrayIds = []
        arrayIds = items?.map((user) => user.id)

        contractorUsers.map((obj) => {
            if (arrayIds.includes(obj.id)) obj.isDisabled = true
            else obj.isDisabled = false
        })

        users.current = contractorUsers
	}

    const handleFilesDrop = (droppedFiles, supIndex) => {
        let supervisor = form.technicalSupervisors[supIndex]
        droppedFiles.forEach((file) => {
            supervisor?.supervisorAttachments.push(file)
        })
    }

    function deleteAttach(index, supIndex) {
        const updatedForm = { ...form };

        let supervisor = form.technicalSupervisors[supIndex]
        if (supervisor && supervisor?.supervisorAttachments.length > 0) {
            const updatedAttachments = [...supervisor.supervisorAttachments];
            updatedAttachments.splice(index, 1);

            supervisor.supervisorAttachments = updatedAttachments;
            updatedForm.technicalSupervisors[supIndex] = supervisor;

            setForm(updatedForm);

        }
    }

    async function downloadFile(data) {
        let downloadFile = data

        if (data.oldFile === true) {
            let urlToFile = new UrlToFile();
            let urlResource = data.route?.startsWith("http") ? `${(data.route)}` : `${Router.apiBaseUrl}/${(data.route)}`
            downloadFile = await urlToFile.convert(urlResource, null, false)
        }

        const url = window.URL.createObjectURL(new Blob([downloadFile]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', data.name || data.route?.toString().split('?')[0].split(/[\/\\]/).pop());
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    }

    return (
        <Container>
            <Header>{titleHeader?.contractsTechnicalSupervisor || ""}</Header>
            <Body>
                <TechnicalSupervisor
                    form={form}
                    users={users.current}
                    handleChange={handleChange}
                    plantList={plantList}
                    handleAddRow={handleAddRow}
                    handleRemoveRow={handleRemoveRow}
                    disabled={loading}
                    handleFilesDrop={handleFilesDrop}
                    deleteAttach={deleteAttach}
                    downloadFile={downloadFile}
                />
            </Body>
            <Footer>
                <SectionRight>
                    <FormButton
                        variant="outlined"
                        type="close"
                        onClick={() => handleClose(false)}
                    >
                        {t('common.cancel')}
                    </FormButton>
                    <Spacer />
                    <FormButton
                        variant="contained"
                        onClick={checkForm}
                    >
                        {t('common.save')}
                    </FormButton>

                </SectionRight>
            </Footer>
            <BackdropLoading open={loading} />
        </Container>
    )
}