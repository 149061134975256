import React from 'react';
import { Divider, Grid, Box, useMediaQuery } from '@mui/material';

// Icons
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import MapIcon from '@mui/icons-material/Map';
import { SlowMotionVideo } from '@mui/icons-material';

// Styles
import {
  CustomCard,
  Title,
  Subtitle,
  Description,
  ButtonCustom,
  ContainerGroupButtons,
  ContainerButtons,
  ContainerButtonsStatus,
  ContainerStatus,
  ContainerWorkflows,
  Container,
  ContainerContent,
} from './styles';

// Utils
import { useColors } from "../../../Context/ColorsContext"
import { getIcon } from '../../../utils/iconConfig';
import { FormatDate } from '../../../utils/formatDate';
import { useTranslation } from 'react-i18next'
import { PlotPlanView } from '../../PlotPlanView';


// Permissions
import { useAbility } from '@casl/react';
import { AbilityContext } from '../../../Context/PermissionsContext';

export default function WorkPermitDetailData({ data, workflow, handleOpenStatus, isExternal, idDisable, setOpendownloadValidations, canDownloadRevalidations }) {
  const { t } = useTranslation();
  const { format } = new FormatDate();
  const { colors } = useColors();
  const ability = useAbility(AbilityContext);
  const isMediumOrSmaller = useMediaQuery(theme => theme.breakpoints.down('md'));

  // Plot Plan modal
  const [plotPlanModal, setPlotPlanModal] = React.useState(false);
  const [plotPlanData, setPlotPlanData] = React.useState({});

  function openPlotPlan() {
    setPlotPlanData(data)
    setPlotPlanModal(true)
  }

  React.useEffect(() => {
  }, [workflow])

  return (
    <React.Fragment>
      <ContainerContent>
        <Grid item xs={12} sx={{ height: '100%' }}>
          {/* Pantalla portatil y desktop  */}
          {!isMediumOrSmaller && (
            <CustomCard>
              <Title variant="h6">
                {t('workflow.detail.mainInfo')}
              </Title>
              <Divider></Divider>

              <Box sx={{ display: 'flex', flexDirection: 'row', gap: '0.5rem' }}>
                <Grid container display='flex' flexDirection='column' xs={10}>
                  <Grid container pt={2} mb={2}>
                    <Grid item xs={2} lg={3}>
                      <Subtitle variant="body2" component="div">{t('workflow.detail.number')}</Subtitle>
                      <Description variant="body2" component="div">{data?.code ? data?.code : data?.id}</Description>
                    </Grid>

                    {data?.plant?.name &&
                      <Grid item xs={4} lg={3}>
                        <Subtitle variant="body2" component="div">{t('workflow.table.plant')}</Subtitle>
                        <Description variant="body2" component="div">{data.plant?.name}</Description>
                      </Grid>
                    }

                    <Grid item xs={2}>
                      <Subtitle variant="body2" component="div">{t('workflow.detail.contract')}</Subtitle>
                      <Description variant="body2" component="div">{data?.contract?.code}</Description>
                    </Grid>

                    <Grid item xs={4}>
                      <Subtitle variant="body2" component="div">{t('workflow.detail.validityInstance')}</Subtitle>
                      <Description variant="body2" component="div">{`
                            ${t('common.from')} ${format({ date: data?.characteristics?.validityFrom, formatWithoutHour: true })} 
                            ${t('common.to')} ${format({ date: data?.characteristics?.validityTo, formatWithoutHour: true })}`} </Description>
                    </Grid>
                  </Grid>

                  <Grid container>
                    {data?.title &&
                      <Grid item xs={6}>
                        <Subtitle variant="body2" component="div">{t('workflow.detail.title')}</Subtitle>
                        <Description variant="body2" component="div">{data?.title}</Description>
                      </Grid>
                    }

                    <Grid item xs={2}>
                      <Subtitle variant="body2" component="div">{t('workflow.detail.lastUpdate')}</Subtitle>
                      <Description variant="body2" component="div">{format({ date: data?.updatedAt, showHourShort: true })}</Description>
                    </Grid>

                    <Grid item xs={4}>
                      <ContainerButtonsStatus>
                        <ContainerStatus>
                          <div>
                            <Subtitle variant="body2" component="div">{t('workflow.detail.status')}</Subtitle>
                          </div>
                        </ContainerStatus>

                        <ContainerWorkflows>
                          <Container>
                            {getIcon(data?.workflowStatus)}
                          </Container>
                          <Container>
                            <Description variant="body2" component="div">{data?.workflowStatus?.subtitle}</Description>
                            <Description variant="body2" component="div">{data?.workflowStatus?.title}</Description>
                            <Description variant="body2" component="div">{data?.workflowStatus?.date}</Description>
                          </Container>
                        </ContainerWorkflows>
                      </ContainerButtonsStatus>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container xs={2}>
                  {!isExternal &&
                    <ContainerGroupButtons>
                      {(ability.can("PlotPlanList") && ability.can("GetPlotPlan")) &&
                        <ContainerButtons>
                          <ButtonCustom
                            onClick={() => openPlotPlan()}
                            variant="contained"
                            endIcon={<MapIcon />}
                          >
                            {t('workflow.detail.plotPlan')}
                          </ButtonCustom>
                        </ContainerButtons>
                      }

                      {
                        canDownloadRevalidations.current &&
                        <ContainerButtons>
                          <ButtonCustom
                            onClick={() => setOpendownloadValidations(true)}
                            variant="contained"
                            endIcon={<DownloadForOfflineIcon />}
                          >
                            {t('common.actions.revalidations')}
                          </ButtonCustom>
                        </ContainerButtons>
                      }

                      <ContainerButtons>
                        <ButtonCustom
                          onClick={() => handleOpenStatus(data)}
                          variant="contained"
                          endIcon={<SlowMotionVideo />}
                          disabled={idDisable === 10003 || idDisable === 10002}
                        >
                          {t('common.actions.process')}
                        </ButtonCustom>
                      </ContainerButtons>
                    </ContainerGroupButtons>
                  }
                </Grid>
              </Box>

              <Grid container mt={2}>
                {(data?.workflowStatus?.statusId === 10003 && data?.cancelObservations) &&
                  <Grid container my={2}>
                    <Grid item xs={12}>
                      <Subtitle variant="body2" component="div">{t('forms.characteristics.deletionJustification')}</Subtitle>
                      <Description variant="body2" component="div">{data?.cancelObservations}</Description>
                    </Grid>
                  </Grid>
                }
              </Grid>

            </CustomCard>
          )}

          {/* Se renderiza al tener una pantalla inferior a 768 */}
          {isMediumOrSmaller && (
            <CustomCard>
              <Title variant="h6">
                {t('workflow.detail.mainInfo')}
              </Title>
              <Divider></Divider>
              <Grid container mb={2} pt={2}>
                <Grid item xs={8}>
                  <Subtitle variant="body2" component="div">{t('workflow.detail.number')}</Subtitle>
                  <Description variant="body1" component="div">{data?.code ? data?.code : data?.id}</Description>
                </Grid>
                <Grid item xs={4}></Grid>
              </Grid>
              {data?.title &&
                <Grid container mb={2}>
                  <Grid item xs={12}>
                    <Subtitle variant="body2" component="div">{t('workflow.detail.title')}</Subtitle>
                    <Description variant="body1" component="div">{data?.title}</Description>
                  </Grid>
                </Grid>
              }
              {data?.plant?.name &&
                <Grid container mb={2}>
                  <Grid item xs={12}>
                    <Subtitle variant="body2" component="div">{t('workflow.table.plant')}</Subtitle>
                    <Description variant="body1" component="div">{data.plant?.name}</Description>
                  </Grid>
                </Grid>
              }

              <Grid container mb={2}>
                <Grid item xs={12}>
                  <Subtitle variant="body2" component="div">{t('workflow.detail.contract')}</Subtitle>
                  <Description variant="body2" component="div">{data?.contract?.code}</Description>
                </Grid>
              </Grid>

              <Grid container mb={2}>
                <Grid item xs={12}>
                  <Subtitle variant="body2" component="div">{t('workflow.detail.validityInstance')}</Subtitle>
                  <Description variant="body1" component="div">{`
                    ${t('common.from')} ${format({ date: data?.characteristics?.validityFrom, formatWithoutHour: true })} 
                    ${t('common.to')} ${format({ date: data?.characteristics?.validityTo, formatWithoutHour: true })}`} </Description>
                </Grid>
              </Grid>

              <Subtitle variant="body2" component="div">{t('workflow.detail.lastUpdate')}</Subtitle>
              <Description variant="body1" component="div">{format({ date: data?.updatedAt, showHourShort: true })}</Description>


              <ContainerButtonsStatus>
                <ContainerStatus>
                  <div>
                    <Subtitle variant="body2" component="div">{t('workflow.detail.status')}</Subtitle>
                  </div>
                </ContainerStatus>

                <ContainerWorkflows>
                  <Container>
                    {getIcon(data?.workflowStatus)}
                  </Container>
                  <Container>
                    <Description variant="body2" component="div">{data?.workflowStatus?.subtitle}</Description>
                    <Description variant="body1" component="div">{data?.workflowStatus?.title}</Description>
                    <Description variant="body2" component="div">{data?.workflowStatus?.date}</Description>
                  </Container>
                </ContainerWorkflows>
              </ContainerButtonsStatus>

              {(data?.workflowStatus?.statusId === 10003 && data?.cancelObservations) &&
                <Grid container my={2}>
                  <Grid item xs={12}>
                    <Subtitle variant="body2" component="div">{t('forms.characteristics.deletionJustification')}</Subtitle>
                    <Description variant="body1" component="div">{data?.cancelObservations}</Description>
                  </Grid>
                </Grid>
              }

              {!isExternal &&
                <ContainerGroupButtons>
                  {(ability.can("PlotPlanList") && ability.can("GetPlotPlan")) &&
                    <ContainerButtons>
                      <ButtonCustom
                        onClick={() => openPlotPlan()}
                        variant="contained"
                        endIcon={<MapIcon />}
                      >
                        {t('workflow.detail.plotPlan')}
                      </ButtonCustom>
                    </ContainerButtons>
                  }

                  {
                    canDownloadRevalidations.current &&
                    <ContainerButtons>
                      <ButtonCustom
                        onClick={() => setOpendownloadValidations(true)}
                        variant="contained"
                        endIcon={<DownloadForOfflineIcon />}
                      >
                        {t('common.actions.revalidations')}
                      </ButtonCustom>
                    </ContainerButtons>
                  }

                  <ContainerButtons>
                    <ButtonCustom
                      onClick={() => handleOpenStatus(data)}
                      variant="contained"
                      endIcon={<SlowMotionVideo />}
                      disabled={idDisable === 10003 || idDisable === 10002}
                    >
                      {t('common.actions.process')}
                    </ButtonCustom>
                  </ContainerButtons>
                </ContainerGroupButtons>
              }
            </CustomCard>
          )}
        </Grid>
      </ContainerContent>

      <PlotPlanView
        data={plotPlanData}
        openDialog={plotPlanModal}
        handleCloseDialog={() => setPlotPlanModal(false)}
      />
    </React.Fragment>
  );
}
