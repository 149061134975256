import React, { useState, Fragment, useEffect, memo } from "react";
import moment from "moment";
// Components
import { Grid } from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

// Custom components
import { TableToolbar } from "./TableToolbar";
import { TableHeader } from "./TableHeader";
import { returnStringFullnames, returnStringCertificateNames } from '../../../../utils/commonFunctions';
import MenuSpeedDial from "../../SpeedDial/SpeedDial";

// Styles
import {
  Cell,
  CustomIconButton,
  CustomLink,
  IconArea,
  TableContent,
  CustomBox,
  ContainerGroup,
  ContainerActions,
  ContainerSpeedDial,
} from "./styles";

// API

// Router
import { Link, useParams } from "react-router-dom";

// Icons
import {
  ContentCopy,
  Delete,
  Edit,
  SlowMotionVideo,
  Visibility,
} from "@mui/icons-material";

// Servicess

//Utils
import { ColorsContext } from "../../../../Context/ColorsContext";
import { FormatDate } from "../../../../utils/formatDate";
import { getIcon } from "../../../../utils/iconConfig";
import { useTranslation } from "react-i18next";
// import { useSortByColumn } from "../../../../Hooks/useSortByColumn";
import Router from "../../../../router";


export default memo(function TableCompanyWorkers({
  headers,
  filteredRequest,
  initialDate,
  setInitialDate,
  setFinalDate,
  finalDate,
  handleDatesFilter,
  handleClearDate,
  handleCreateNew,
  labelCreateNew,
  handleDetail,
  handleEdit,
  handleProcess,
  handleOpenDuplicate,
  handleDelete,
  hideFilterDate,
  hideToolbar,
  preventOrderBy,
  customAdd,
  elementAdd,
  custom,
  defaultSort,
  defaultRowsPerPage,
  rows,
  selectedTags,

  setFilteredRequest,
  listWorkFlows,
  listPlants,

  filterStatus,
  standardStatus,
  workflowStatus,

  setWorkflowStatus,

  loadWorkflowsFilters,
  setLoading,

  counterFlow,
  counterState,
  setCounterFlow,
  setCounterState,

  isStandardFlows,
  idListDetail,
  isFormV2,
  isForm,
  isList,

  isMywork,
  setMaintainFilter,
  copyData,
  setCopyData,
  validityTooltip,
  addDataSpeedDial,
  needSpeedDial,

  isCustom,
	onChangePage,
  currentPage,
  pageSize,
  rowsCount
}) {
  const { format } = new FormatDate();
  const { t } = useTranslation();
  const params = useParams();
  const { colors } = React.useContext(ColorsContext);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage || 10);
  // const { order, orderBy, getComparator, stableSort, handleRequestSort } = useSortByColumn({ defaultSort });

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("");
  const [customSort, setCustomSort] = React.useState("");

  function descendingComparator(a, b, orderBy, customSort) {
    let cond1 = b[orderBy] < a[orderBy]
    let cond2 = b[orderBy] > a[orderBy]
  
    if (customSort) {
      cond1 = eval(`b.${customSort}`) < eval(`a.${customSort}`)
      cond2 = eval(`b.${customSort}`) > eval(`a.${customSort}`)
      if (eval(`a.${customSort}`) === null) return 1
      if (eval(`b.${customSort}`) === null) return -1
    }
  
    if (cond1) {
      return -1;
    }
    if (cond2) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy, customSort) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy, customSort)
      : (a, b) => -descendingComparator(a, b, orderBy, customSort);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const handleRequestSort = (event, property, property2, defaultOrder) => {
    const isAsc = orderBy === property && order === "asc";
    if (defaultOrder) {
      setOrder(defaultOrder);
    }
    else {
      setOrder(isAsc ? "desc" : "asc");
    }
    setOrderBy(property);
    setCustomSort(property2);
  };

  useEffect(() => {
    setPage(0);
    if (isCustom) {
      setRowsPerPage(pageSize)
    }
  }, [headers]);

  useEffect(() => {
    if (isCustom) {
      setPage(currentPage);
    }
    else {
      setPage(0);
    }
  }, [filteredRequest]);

  const handleChangePage = async(_, newPage) => {
    if (isCustom) {
      await onChangePage(newPage, rowsPerPage)
    }
    setPage(newPage);
  };

  const handleChangeRowsPerPage = async(event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    if (isCustom) {
      await onChangePage(0, event.target.value);
    }
  };

  const getInstanceTime = (dataTime, type = "activeTime") => {
    if (dataTime !== null) {
      let tempTime = moment.duration(dataTime.activeTime);

      if (type !== "activeTime")
        tempTime = moment.duration(dataTime.suspendedTime);

      const hoursVal = tempTime.hours();
      const minutesVal = tempTime.minutes();
      const secondsVal = tempTime.seconds();

      let textTime = hoursVal > 0 ? `${hoursVal} h` : "";
      textTime +=
        " " + (minutesVal > 0 ? `${minutesVal} min` : `${secondsVal} seg`);

      return <span>{textTime}</span>;
    }

    return "";
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <CustomBox hideFilterDate={hideFilterDate} isList={isList}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        {!hideToolbar && (
          <TableToolbar
            filterStatus={filterStatus}
            standardStatus={standardStatus}
            workflowStatus={workflowStatus}
            setWorkflowStatus={setWorkflowStatus}
            loadWorkflowsFilters={loadWorkflowsFilters}
            selectedTags={selectedTags}
            setFilteredRequest={setFilteredRequest}
            rows={rows}
            initialDate={initialDate}
            finalDate={finalDate}
            setInitialDate={setInitialDate}
            setFinalDate={setFinalDate}
            handleDatesFilter={handleDatesFilter}
            handleClearDate={handleClearDate}
            handleCreateNew={handleCreateNew}
            title={labelCreateNew}
            hideFilterDate={hideFilterDate}
            customAdd={customAdd}
            elementAdd={elementAdd}
            listWorkFlows={listWorkFlows}
            listPlants={listPlants}
            isStandardFlows={isStandardFlows}
            idListDetail={idListDetail}
            isFormV2={isFormV2}
            isForm={isForm}
            isList={isList}
            isMywork={isMywork}
            setLoading={setLoading}
            setMaintainFilter={setMaintainFilter}
            copyData={copyData}
            setCopyData={setCopyData}
          />
        )}
        <TableContainer>
          <Table
            sx={{ minWidth: 750, }}
            aria-labelledby="tableTitle"
            size="small"
          >
            <TableHeader
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headers={headers}
            />

            <TableContent>
              {stableSort(filteredRequest, getComparator(order, orderBy, customSort), preventOrderBy)
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((row, index) => {
                  return (
                    <TableRow key={row.id} hover role="checkbox" tabIndex={-1} sx={{ verticalAlign: 'super' }}>
                      {headers.map((value) => (
                        <Cell
                          key={"head-" + value.id}
                          data={value.id}
                        >
                          {value.isRender ? (
                            <>
                              {value.id2 ? (
                                <Fragment>{row[value.id2][value.id]}</Fragment>
                              ) : (
                                <>
                                  {value.link ? (
                                    <>
                                      <Link to={Router.appFormManagementDetailV2} state={{ row: row }}>
                                        <span style={{ color: colors.detailTextColor }}>
                                          {row[value.id]}
                                        </span>
                                      </Link>
                                    </>
                                  ) : (
                                    <Fragment>{row[value.id]}</Fragment>
                                  )}
                                </>
                              )}
                            </>
                          ) : (
                            <Fragment>
                              <Tooltip title={value.needTooltip && value.needTooltip(row)}>
                                <div>
                                  {value.format && value.format(row)}
                                </div>
                              </Tooltip>
                              {(value.type === "activeTime" ||
                                value.type === "suspendedTime") && (
                                  <Fragment>
                                    {getInstanceTime(
                                      row?.instanceTime,
                                      value.type
                                    )}
                                  </Fragment>
                                )}
                              {value.type === "date" && (
                                <Fragment>
                                  {format({ date: row[value.id], format: true })}
                                </Fragment>
                              )}
                              {value.type === "shortDate" && (
                                <Fragment>
                                  {format({ date: row[value.id], format: true })}
                                </Fragment>
                              )}
                              {value.type === "object-icon" && (
                                <Grid
                                  container
                                  alignItems="center"
                                  direction="row"
                                  justifyContent="space-between"
                                >
                                  {value.keys?.map((key) => (
                                    <Grid
                                      key={"object-" + key}
                                      item
                                      xs={key === "icon" ? 2 : 6}
                                    >
                                      <Fragment>
                                        {row.element !== "" && (
                                          <IconArea>{row.element}</IconArea>
                                        )}
                                        {row[value.id]?.[key]}
                                      </Fragment>
                                    </Grid>
                                  ))}
                                </Grid>
                              )}
                              {value.type === "object" && (
                                <ContainerGroup style={{ justifyContent: 'start', alignItems: 'flex-start' }}>
                                  {value.keys?.map((key) => (
                                    <Grid
                                      key={"object-" + key}
                                      item
                                      xs={key === "icon" ? 2 : 6}
                                    >
                                      {key === "icon"
                                        ? getIcon(row[value.id])
                                        : row[value.id]?.[key]}
                                    </Grid>
                                  ))}
                                </ContainerGroup>
                              )}

                              {value.type === "custom" && (
                                <ContainerGroup needMagin={true}>{custom(row?.workflowStatus)}</ContainerGroup>
                              )}
                              {value.type === "link" && (
                                <CustomLink onClick={() => value.onClick(row)}>
                                  {row.linkName || row.code}
                                </CustomLink>
                              )}
                              {value.type === "iconButton" && (
                                <CustomIconButton
                                  size="small"
                                  onClick={() => value.onClick(row)}
                                >
                                  {value.icon}
                                </CustomIconButton>
                              )}
                              {value.type === "validity" && (
                                <Tooltip title={validityTooltip(row[value.id], row[value.id2])}>
                                  <div style={{ display: 'flex', }}>
                                    {format({ date: row[value.id], formatWithoutHour: true })}
                                  </div>
                                </Tooltip>
                              )}
                              {value.type === "contractors" && (
                                row.contractors.length > 1 ? (
                                  <Tooltip title={returnStringFullnames(row.contractors, true)}>
                                    <ContainerGroup alignStart={true}>
                                      <div>
                                        <span>{`${row.contractors[0]?.name ?? ''} ${row.contractors[0]?.lastName ?? ''}`}</span>
                                      </div>
                                      <div>
                                        <IconArea grouped={true}>{`+${row.contractors.length - 1}`}</IconArea>
                                      </div>
                                    </ContainerGroup>
                                  </Tooltip>
                                ) : (
                                  <span>{returnStringFullnames(row.contractors)}</span>
                                )
                              )}
                              {value.type === "technicalSupervisors" && (
                                row.technicalSupervisors.length > 1 ? (
                                  <Tooltip title={returnStringFullnames(row.technicalSupervisors, true)}>
                                    <ContainerGroup alignStart={true}>
                                      <div>
                                        <span>{`${row.technicalSupervisors[0]?.name ?? ''} ${row.technicalSupervisors[0]?.lastName ?? ''}`}</span>
                                      </div>
                                      <div>
                                        <IconArea grouped={true}>{`+${row.technicalSupervisors.length - 1}`}</IconArea>
                                      </div>
                                    </ContainerGroup>
                                  </Tooltip>
                                ) : (
                                  <span>{returnStringFullnames(row.technicalSupervisors)}</span>
                                )
                              )}
                              {value.type === "certificates" && (
                                row.certificates.filter((item) => item.status).length > 1 ? (
                                  <Tooltip title={returnStringCertificateNames(row.certificates, true)}>
                                    <ContainerGroup alignStart={true}>
                                      <div>
                                        <span>{`${row.certificates.filter((item) => item.status)[0].name ?? ''}`}</span>
                                      </div>
                                      <div>
                                        <IconArea grouped={true}>{`+${row.certificates.filter((item) => item.status).length - 1}`}</IconArea>
                                      </div>
                                    </ContainerGroup>
                                  </Tooltip>
                                ) : (
                                  <span>{returnStringCertificateNames(row.certificates)}</span>
                                )
                              )}
                              {value.type === "actions" && (
                                <>
                                  <ContainerGroup center={value?.center ?? false} needSpeedDial={needSpeedDial}>
                                    {value.keys?.map((key) => (

                                      <Grid key={"actions-" + key} item>

                                        {key === "edit" && (
                                          <Tooltip
                                            title={t("common.actions.edit")}
                                          >
                                            <CustomIconButton
                                              size="small"
                                              onClick={() => handleEdit(row)}
                                            >
                                              <Edit />
                                            </CustomIconButton>
                                          </Tooltip>
                                        )}
                                        {key === "process" && (
                                          <Tooltip
                                            title={t("common.actions.process")}
                                          >
                                            <CustomIconButton
                                              size="small"
                                              onClick={() => handleProcess(row)}
                                            >
                                              <SlowMotionVideo />
                                            </CustomIconButton>
                                          </Tooltip>
                                        )}
                                        {key === "duplicate" && (
                                          <Tooltip
                                            title={t("common.actions.duplicate")}
                                          >
                                            <CustomIconButton
                                              size="small"
                                              onClick={() =>
                                                handleOpenDuplicate(row)
                                              }
                                            >
                                              <ContentCopy />
                                            </CustomIconButton>
                                          </Tooltip>
                                        )}
                                        {key === "delete" && (
                                          <Tooltip
                                            title={t("common.actions.cancel")}
                                          >
                                            <span>
                                              <CustomIconButton
                                                size="small"
                                                onClick={() => handleDelete(row, index)}
                                                disabled={
                                                  row.disabledDelete !== undefined
                                                    ? row.disabledDelete
                                                    : false
                                                }
                                              >
                                                <Delete />
                                              </CustomIconButton>
                                            </span>
                                          </Tooltip>
                                        )}
                                        {(key === "custom" || key === "custom2") && (value.actionsFormat(row, key))}
                                      </Grid>
                                    ))}
                                  </ContainerGroup>

                                  {needSpeedDial && (
                                    <ContainerSpeedDial needSpeedDial={needSpeedDial}>
                                      <MenuSpeedDial
                                        options={addDataSpeedDial(row)}
                                      />
                                    </ContainerSpeedDial>
                                  )}

                                </>

                              )}
                            </Fragment>
                          )}
                        </Cell>
                      ))}
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 33 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableContent>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rowsCount}
          rowsPerPage={rowsPerPage}
          page={page}
          showFirstButton={true}
          showLastButton={isCustom ? false : true}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={t("common.itemsPerPage")}
          labelDisplayedRows={({ from, to, count }) => {
            return "" + from + "-" + to + ` ${t("common.of")} ` + count;
          }}
          sx={{ borderTop: "1px solid " + colors.gray }}
        />
      </Paper>
    </CustomBox>
  );
});
