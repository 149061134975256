import * as React from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';

// Utils
import { useTranslation } from 'react-i18next'
import { ButtonText, CustomIconButton } from './styles';

export const SearchBarCompanyWorkers = ({
    handleChangeFilter,
    handleFilters,
    searchText,
    clearFilter 
}) => {

    const { t } = useTranslation();
    const [search, setSearch] = React.useState('')

    function handleSearch(e) {
        setSearch(e)
        searchText.current = e
    }

    React.useEffect(() => {
        handleSearch("")
    },[clearFilter?.current])

    return (
        <Paper
            component="form"
            sx={{ paddingLeft: '10px', display: 'flex', alignItems: 'center', width: '100%', height: '100%' }}
        >
            <InputBase
                type='text'
                name='search'
                value={search}
                onChange={e => handleSearch(e.target.value)}
                onKeyDown={e => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      handleFilters()
                    }
                }}
                autoComplete='off'

                sx={{ display: 'flex', flex: 1, ml: 1, }}
                placeholder={t('common.searchBars.worker')}
                inputProps={{ 'aria-label': 'search google maps' }}
            />
            <CustomIconButton type="button" aria-label="search" onClick={handleChangeFilter}>
                <ButtonText pr={2} sx={{ textTransform: "uppercase" }}>
                  {t("common.search")}
                </ButtonText>
                <SearchIcon />
            </CustomIconButton>
        </Paper>
    );
}