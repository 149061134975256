import React from 'react'
import { Grid } from '@mui/material';

// Components
import { InternalRoleWithoutContract } from './InternalRoleWithoutContract';
import { InternalRoleWithContract } from './InternalRoleWithContract';
import { ExternalRoleWithContract } from './ExternalRoleWithContract';

// Styles
import {
  Container,
  Body,
  ContainerInfo
} from '../styles'

export const StepTwo = ({
  data,
  roleType,
  handleChangeStepTwo,
  categories,
  plants,
  companies,
  errors,
  setErrors,
  handleSetCompany,
  handleSetPlants,
  selectedCompany,
  selectedPlants,
  companyContracts,
  form,
  setForm,
  user,
  isSupervisor,
  showCheckSupervisor
}) => {

  React.useEffect(() => {
  }, [categories, plants, roleType])

  return (
    <Container>
      <Body>
        <ContainerInfo>
          {roleType === 'internalWithoutContract' &&
            <InternalRoleWithoutContract
              data={data}
              roleType={roleType}
              categories={categories}
              plants={plants}
              selectedPlants={selectedPlants}
              errors={errors}
              form={form}
              setForm={setForm}
              handleChange={handleSetPlants}
              isSupervisor={isSupervisor}
            />
          }
          {roleType === 'internalWithContract' &&
            <InternalRoleWithContract
              data={data}
              handleChangeStepTwo={handleChangeStepTwo}
              form={form}
              setForm={setForm}
              errors={errors}
              setErrors={setErrors}
              isSupervisor={isSupervisor}
              showCheckSupervisor={showCheckSupervisor}
            />
          }
          {roleType === 'externalWithContract' &&
            <ExternalRoleWithContract
              companies={companies}
              handleSetCompany={handleSetCompany}
              selectedCompany={selectedCompany}
              companyContracts={companyContracts}
              data={data}
              handleChangeStepTwo={handleChangeStepTwo}
              form={form}
              setForm={setForm}
              errors={errors}
              setErrors={setErrors}
              user={user}
              isSupervisor={isSupervisor}
            />
          }
        </ContainerInfo>
      </Body>
    </Container>
  )
}
