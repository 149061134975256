import styled from 'styled-components';
import withColors from '../../../../styles/withColors'

const tabletStartWidth = 768;
const mobile = `@media (max-width: ${tabletStartWidth}px)`

export const Container = styled.div`
  width:100%;
  height:100%;
  display:flex;
  justify-content:center;
  align-items:center;
  padding-bottom:5em;
  flex-direction:column;
  ${mobile} {
    height:max-content;
    padding:1em;
  }
`

export const Form = styled.form`
  display:flex;
  flex-direction:column;
  align-items:center;
  ${mobile} {
    width:100%;
    padding:0 1em;
  }
`

const TitleStyle = styled.p`
  font-size:1.2rem;
  color:${({ colors }) => colors.blackFont};
  padding-bottom:2em;
  ${mobile} {
    padding-bottom:2.5em;
  }
`

export const SectionRow = styled.section`
  display:flex;
  width:100%;
  padding:.2em 0 .7em;
  align-items:center;
  justify-content:center;
  text-align:left;
  ${mobile} {
    flex-direction:column;
    align-items:initial;
    padding:0;
  }
`

export const SectionInput = styled.section`
  display:flex;
  flex-direction:column;
  width:300px;
  margin-left: ${props => (props.second ? '2em' : '')};
  ${mobile} {
    width:100%;
    margin-top: ${props => (props.bottom ? '1em' : '')};
    margin-left:0;
  }
`

const LabelStyle = styled.label`
  padding-bottom:.3em;
  padding-left:.3em;
  color:${({ colors }) => colors.blackFont};
`

const ContainerInputStyle = styled.div`
  background: ${({ colors }) => colors.gray};
  border-bottom:2px solid ${({ colors }) => colors.gray};
  height:2em;
  padding:0 .5em;
  display:flex;
  align-items:center;
  border-radius:5px;
`

export const Figure = styled.figure`
  display:flex;
  justify-content:center;
  margin-left: ${props => (props.marginLeft ? ".5em" : "")};
  margin-top: ${props => (props.marginTop ? ".3em" : "")};
  margin-bottom: ${props => (props.marginBottom ? ".3em" : "")};
  margin-right: ${props => (props.marginRight ? "1em" : "")};
`

const InputStyle = styled.input`
  background: ${({ colors }) => colors.gray};
  padding-left:1em;
  width:100%;
  color:${({ colors }) => colors.blackFont};
  &::-webkit-inner-spin-button, ::-webkit-outer-spin-button  {
    -webkit-appearance: none; 
    margin: 0; 
  }
  -moz-appearance:textfield;
`

const SpanStyle = ({ colors, color, ...props }) => (
  <p
    {...props}
    style={{
      width: '100%',
      textAlign: 'center',
      color: color ? colors.sectionTitleColor : colors.red,
      paddingTop:'.3em',
      paddingLeft:'.3em',
      marginBottom: '1em',
      height:'1.5em',
      whiteSpace: 'pre-wrap',
      '&:activate': {
        visibility:'visible',
        transition:'all .3s ease',
      },
      '&:closed': {
        visibility:'hidden',
        transition:'all .3s ease-in-out',
      }
    }}
  />
)


export const SectionBtn = styled.section`
  text-align: center;
  padding:1em 0 2em;
  width:100%;
  ${mobile} {
    padding:1.5em 0 2em;
  }
`

const BtnSubmitStyle = ({ colors, disabled, ...props }) => (
  <button
    {...props}
    style={{
      color: colors.white,
      background: disabled ? colors.gray : colors.buttonActionColor,
      cursor: disabled ? 'not-allowed' : 'pointer',
      padding: '.5em 2em',
      borderRadius: '5px',
      display: 'inline-block',
      justifyContent: 'center',
      transition: 'all .3s ease-in-out',
      width: '50%',
      '&:hover': {
        transition: 'all .3s ease',
        opacity: '.8',
      },
      '&:active': {
        transition: 'all .3s ease',
        opacity: '1',
      },
    }}
  />
);

const SpanLinkStyle = styled.p`
  width: 100%;
  text-align: center;
  color: ${({ colors }) => colors.blackFont};
  text-decoration: underline;
  padding-top:.3em;
  padding-left:.3em;
  height:1.5em;
  &.activate {
    visibility:visible;
    transition:all .3s ease;
  }
  &.closed {
    visibility:hidden;
    transition:all .3s ease-in-out;
  }
`

export const Title = withColors(TitleStyle);
export const Label = withColors(LabelStyle);
export const ContainerInput = withColors(ContainerInputStyle);
export const Input = withColors(InputStyle);
export const Span = withColors(SpanStyle);
export const BtnSubmit = withColors(BtnSubmitStyle);
export const SpanLink = withColors(SpanLinkStyle);