import React from 'react';
import { Link } from 'react-router-dom';
import Router from '../../../../router';

import Recaptcha from 'react-google-recaptcha';

import {
    Container,
    Form,
    Title,
    SectionRow,
    SectionInput,
    Label,
    ContainerInput,
    Input,
    Span,
    SectionBtn,
    BtnSubmit,
    SpanLink,
    LabelCustom,
} from './styles'

import { Title as TitleBold } from '../LoginForm/styles';

import { useTranslation } from 'react-i18next'

export const PasswordRecoveryForm = ({ 
    form, 
    setForm,
    isLoading, 
    isSuccess,
    handleChange, 
    handleSubmit, 
    messageResponse,
    recaptchaRef,
    colors,
}) => {
    const { t } = useTranslation();

    const getLoginIcon = () => {

        if (colors?.loginIconUrl === null || colors?.loginIconUrl === undefined || colors?.loginIconUrl === "") {
            return (
                <img src='cenit-logo.png' width={300} alt='logo' />
            )
        }

        return (colors?.loginIconUrl !== null && colors?.loginIconUrl !== "" && colors?.loginIconUrl !== undefined) ?
            <img src={`${Router.apiBaseUrl}/${colors.loginIconUrl.toString()}`} width={300} alt='logo' />
            : null
    }

    const handleRecaptchaVerify = (response) => {
        setForm({ ...form, reCaptchaToken: response })
    };

    return (
        <Container>
            <Form onSubmit={handleSubmit}>
                {getLoginIcon()}
                <TitleBold>{(colors?.title !== null && colors?.title !== "" && colors?.title !== undefined ? colors.title : '')}</TitleBold>
                <Title>{t('password.passwordRecovery')}</Title>
                {!isSuccess && 
                    <SectionRow>
                        <SectionInput>
                            <LabelCustom>{t('password.user')}</LabelCustom>
                            <ContainerInput>
                                <Input
                                    name='username'
                                    value={form.username}
                                    onChange={(e) => handleChange(e, 'username')}
                                    disabled={isLoading}
                                    required
                                />
                            </ContainerInput>
                        </SectionInput>
                    </SectionRow>
                }
                {process.env.REACT_APP_CAPTCHA !== undefined && !isSuccess &&
                    <SectionRow>
                        <div style={{ margin: '0 auto' }}>
                            <Recaptcha ref={recaptchaRef} sitekey={process.env.REACT_APP_CAPTCHA} onChange={handleRecaptchaVerify} />
                        </div>
                    </SectionRow>
                }
                <SectionBtn>
                    {isSuccess && 
                        <Link to={Router.appLogin} disabled={isLoading}>
                            <SpanLink>{t('password.goToLogin')}</SpanLink>
                        </Link>
                    }
                    {!isSuccess && (
                        <>
                            <BtnSubmit type='submit' disabled={isLoading || form.reCaptchaToken === null}>{t('common.send')}</BtnSubmit>
                            <Link to={Router.appLogin} disabled={isLoading}>
                                <SpanLink>{t('common.cancel')}</SpanLink>
                            </Link>
                        </>
                        )
                    }
                </SectionBtn>
                {messageResponse !== '' &&
                    <SectionRow>
                        <SectionInput>
                            <Span color={isSuccess}>{messageResponse}</Span>
                        </SectionInput>
                    </SectionRow>
                }
            </Form>
        </Container>
    )
}