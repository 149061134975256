import { Box, MenuItem } from "@mui/material";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import { styled } from "@mui/material/styles";
import { useColors } from "../../../../Context/ColorsContext";
import { MediaQuerys } from "../../../../styles/GlobalStyle";

const { mobile, tablet ,desktop } = MediaQuerys;

export const Container = styled('div')(() => ({
  display: 'flex',
  width: '100%',
  minWidth: '500px',
  height: '100%',
  flexDirection: 'column',
  [mobile]: {
    minWidth: '380px',
  }
}))

export const Header = styled('div')(() => {
  const { colors } = useColors();
  return {
    display: 'flex',
    background: colors.white,
    color: colors.detailTextColor,
    width: '100%',
    height: 'fit-content',
    borderRadius:'10px 10px 0 0',
    padding:'16px',
    textTransform:'uppercase',
    fontWeight:600,
    [desktop]: {
      fontSize: '0.85rem',
    },
  }
})

export const Body = styled('div')(() => {
  const { colors } = useColors();
  return {
    display: 'flex',
    flexDirection:'column',
    flex:'1',
    width: '100%',
    background: colors.white,
  }
})

export const Footer = styled('div')(() => {
  const { colors } = useColors();
  return {
    display: 'flex',
    background: colors.white,
    width: '100%',
    height: 'fit-content',
    borderRadius:'0 0 10px 10px',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '8px 16px',
    margin: 'auto'
  }
})

export const SectionRight = styled('section')(() => ({
  display: 'flex',
  gap: '1rem',
}))

export const BoxContainer = styled(Box)(({theme}) => {
    return {
        maxWidth: 800,
        maxHeight: 500,
        // display: 'flex',
        [theme.breakpoints.up('md')]: {
            minWidth: 700,
            // flexDirection: 'column',
        },
    }
})

export const MenuNotification = styled(MenuItem)(() => {
    const { colors } = useColors();
    return {
        borderBottom: `1px solid ${colors.gray}`
    }
})


export const ContainerDates = styled('div')(() => {
  return {
    display: "flex",
    flexDirection: 'column',
    margin: '10px 30px 30px',
  }
})


export const ContainerGroup = styled("div")(({ needMagin }) => {
  return {
    display: "flex",
    alignItems: "center",
    gap: '1rem',
    flexDirection: "row",
    marginLeft: needMagin && 5,
  };
});

export const CleanIcon = styled(CleaningServicesIcon)(() => {
  const { colors } = useColors();
  return {
      color: colors.white,
      fontSize: 18,
      [tablet]: {
          width: 17,
          height: 17,
          padding: '1px',
          '.MuiSvgIcon-root': {
              width: 16,
              height: 16,
          }
      },
      [desktop]: {
          width: '16px',
          height: '16px',
      }
  };
});


export const ContainerInputsDate = styled("div")(({theme, errorstart, errorend}) => ({
  '& .MuiButtonBase-root': {
      color: (errorstart || errorend) ? theme.palette.error.main : 'inherit', 
  },
  '& .css-4jnixx-MuiStack-root>.MuiTextField-root': {
      minWidth: "80px",
  },
  '& .MuiFormLabel-root': {
      fontSize: "0.8rem",
      [desktop]: {
          fontSize: "0.75rem"
      }
  },
  '& .MuiInputBase-root': {
      fontSize: "0.8rem",
  },
  '& .MuiStack-root': {
      paddingTop: '0px !important',
      overflow: 'visible !important',
  },
  '& .MuiTextField-root': {
      minWidth: '120px !important',
      width: '150px !important',
      [desktop]: {
          width: '120px',
      }
  },
}));