import { styled } from "@mui/material/styles";
import { useColors } from "../../Context/ColorsContext"
import { Card, CardContent, CardHeader, Typography, Icon, Grid } from '@mui/material';
// import { Timeline } from '@mui/lab';
import SearchIcon from '@mui/icons-material/Search';
import TimelineDot from '@mui/lab/TimelineDot';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import { MediaQuerys } from "../../styles/GlobalStyle";

const { mobile, tablet, desktop } = MediaQuerys

export const Container = styled(Card)(() => ({
    // maxHeight: '800px',
    // height: '100vh',
    height: '820px',
    // maxHeight: 'calc(100vh - 100px)',
    width: '100%',
    borderRadius: 6,
    boxShadow: 'rgb(61 71 82 / 20%) 0px 4px 8px',
    padding: '1rem',
    '&::-webkit-scrollbar': {
        width: '4px',
    },
    [tablet]: {
        // minHeight: '320px',
        height: '100%',
        marginTop: 0,
    }
}))

export const Header = styled(CardHeader)(() => {
    const { colors } = useColors();
    return {
        color: colors.main,
        padding: 8,
        textAlign: 'center',
        '.MuiTypography-root': {
            fontSize: 11,
            textTransform: 'uppercase',
            fontWeigth: 'bold'
        }
    }
})

export const ContentSearch = styled(CardContent)(() => {
    const { colors } = useColors();
    return {
        background: colors.background,
        padding: '2px 10px'
    }
})

export const Content = styled(CardContent)(({theme}) => {
    const { colors } = useColors();

    return {
        '&::-webkit-scrollbar': {
            width: '4px',
            height: '4px',
        },
        padding: '5px',
        overflow: 'auto',
        maxHeight: '90%',
        [tablet]: {
            maxHeight: '415px',
        },
    }
})

// export const CustomTimeLine = styled(Timeline)(() => ({
//     padding: 0,
//     margin: 0,
//     '.MuiTimelineItem-root': {
//         minHeight: '50px'
//     },
//     '.MuiTimelineOppositeContent-root': {
//         padding: '4px 10px'
//     }
// }))

export const TypoTimeLine = styled(Typography)(({ type }) => {
    const { colors } = useColors();
    return {
        // color: type === 'title' ? colors.detailTextColor : colors.grayFont,
        color: colors.grayFont,
        fontSize: 11,
        fontWeight: type === 'title' ? 'bold' : 'normal',
        // lineHeight: 1.2
    }
})

export const Search = styled(SearchIcon)(() => {
    const { colors } = useColors();
    return {
        width: 20,
        height: 20,
        cursor: 'pointer',
        color: colors.grayFont
    }
})

// export const IconArea = styled('div')(() => {
//     const { colors } = useColors();
//     return {
//         marginLeft: 2,
//         width: 15,
//         height: 15,
//         fontSize: 11,
//         fontWeight: 'bold',
//         color: colors.white,
//         background: colors.orange,
//         borderRadius: 20,
//         display: 'flex',
//         justifyContent: 'center',
//         alignItems: 'center'
//     }
// })

export const Title = styled(Typography)(() => {
    const { colors } = useColors();
    return {
        color: colors.detailTextColor,
        fontWeight: 'bold',
        [desktop]: {
            fontSize: '1rem',
        }
    }
})

export const CustomIcon = styled(Icon)(() => {
    const { colors } = useColors();
    return {
        color: colors.white,
        fontSize: 20,
        [desktop]: {
            fontSize: '15px',
        }
    }
})


export const CustomTitle = styled('span')(({ type }) => {
    const { colors } = useColors();
    return {
        // color: type === 'title' ? colors.detailTextColor : colors.grayFont,
        color: colors.grayFont,
        fontSize: 11,
        marginLeft: type !== 'title' && '3px',
        fontWeight: type === 'title' ? 'bold' : 'normal',
        [desktop]: {
            marginLeft: 0,
            fontSize: 10,
        }
        // lineHeight: 1.2
    }
})

export const GroupItems = styled('div')(() => {
    return {
        display: 'flex',
    }
})

export const IconArea = styled('div')(() => {
    const { colors } = useColors();
    return {
        width: 15,
        height: 15,
        fontSize: 10,
        fontWeight: 'bold',
        color: colors.white,
        background: colors.buttonActionColor,
        borderRadius: 20,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        // margin: 'auto',
        [desktop]: {
            height: 15,
            width: 15
        }
    }
})


export const ContainerGroup = styled('span')(() => {
    return {
        display: 'flex',
        width: 'auto',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexDirection: 'row',
        gap: '3px'
    }
})

export const CustomGrid = styled('div')(({ fixWidth }) => {
    return {
        display: 'flex',
        width: fixWidth && 'inherit',
        [desktop]: {
            flexDirection: 'column',
        }

    }
})

export const CustomTimelineDot = styled(TimelineDot)(() => {
    return {
        justifyContent: 'center',
        alignItems: 'center',
        width: '32px',
        [desktop]: {
            width: '25px',
            height: '25px'
        }
    }
})

export const CustomTimeLine = styled(Timeline)(() => {
    return {
        display: 'flex',
        padding: 0,
        flexDirection: 'column',
        gap: '0.50rem',
    }
})


export const ContainerLeft = styled('div')(() => {
    return {
        width: '25%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
        flexDirection: 'column',
        marginRight: '7px',
    }
})


export const ContainerCenter = styled('div')(() => {
    return {
        width: '15%',
        // display: 'flex',
        // flexDirection: 'c',
    }
})


export const ContainerRight = styled('div')(() => {
    return {
        width: '65%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        marginLeft: '7px',
        // [desktop]: {

        // }
    }
})

export const CustomTimeLineItem = styled(TimelineItem)(() => {
    return {
        justifyContent: 'flex-start',
        // gap: '0.70rem',
        '&::before': {
            content: 'none',
        },
        // [desktop]: {
        //     gap: '0.50rem',
        // }
    }
})

export const InstaTimeContent = styled('div')(() => {
    return {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '10px',
    }
})

export const ContainerGrid = styled(Grid)(() => {
    return {
        alignItems: 'center',
        justifyContent: 'flex-start',
        flexDirection: 'row',
        gap: '3px',
        [tablet]: {
            alignItems: 'flex-end',
        }
    }
})
