import React from 'react';
import { createGlobalStyle } from 'styled-components'
import { useColors } from '../Context/ColorsContext';

import { styled } from "@mui/material/styles";
import { Autocomplete, TableCell } from "@mui/material";

// const desktopSmallWidth = 1024;
const mobileStartWidth = 426;
const tabletStartWidth = 767;
const desktopStartWidth = 1440;
const desktopStartLargeWidth = 2560;

export const MediaQuerys = Object.freeze({
  mobile: `@media (max-width: ${mobileStartWidth}px)`,
  tablet: `@media (max-width: ${tabletStartWidth}px)`,
  desktop: `@media (max-width: ${desktopStartWidth}px)`,
  desktoplarge: `@media (max-width: ${desktopStartLargeWidth}px)`,
})

const { mobile, tablet, desktop, desktoplarge } = MediaQuerys;

const GlobalStyles = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0; 
    border: 0;
    box-sizing: border-box;
  }

  #root {
    box-shadow: 0 0 10px rgba(0, 0, 0, .05);
    overflow-x: hidden;
    min-height: 100vh;
  }

  html {
    box-sizing: border-box;
    scroll-behavior: smooth;
    font-family: 'Poppins', sans-serif;
    height: 100vh;
    width: 100vw;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  ul, li, h1, h2, h3, h4, h5, p, button {
    margin: 0;
    padding: 0;
    ${desktop}{
      font-size: 0.75rem;
    }
    ${mobile}{
      font-size: 0.75rem;
    }

  }

  ul {
    list-style: none;
  }

  button {
    background: transparent;
    cursor: pointer;
    border: 0;
    outline: 0
  }

  body {
    height: 100vh;
    background: ${props => props.colors.background} !important;
    margin: 0 auto;
    overscroll-behavior: none;
    -webkit-font-smoothing: antialiased;
    width: 100%;
    font-size:16px;
    ${desktop} {
    font-size:12px;
    }
    ${tablet} {
      font-size:12px;
    }
    ${mobile} {
      font-size:12px;
    }

  }

  *:focus {
    outline: 0;
  }

  html, body, #root {
    height: 100%;
  }

  a {
    text-decoration: none;
  }

  label {
    ${tablet}{
      font-size: 2rem;
    }

    
  }

  input[type="date"] {
    font-family: 'Poppins', sans-serif;
    ${mobile} {
      font-size: 2rem;
    }
    ${tablet} {
      font-size: 10px !important;
    }
    ${desktop} {
      font-size: 0.75rem !important;
    }
    ${desktoplarge} {
      font-size: 14px ;
    }
  }

  input:disabled {
    background: ${props => props.colors.disabledInput} !important;
    border-radius: 4px;
  }

  input:disabled:checked {
    background-color: #0d6efd !important;
  }

`

export const StickyColumn = styled(TableCell)(({ direction }) => {
  const { colors } = useColors();
  
  return {
    position: 'sticky',
    [direction]: 0,
    background: colors.white,
    width: "auto",
    border: "none",
  }
})

export const CustomCell = styled(TableCell)(({ dataHead }) => {
  const { colors } = useColors();
  const { sticky, direction } = dataHead

  return {
    position: sticky && 'sticky',
    [direction]: sticky && 0,
    background: sticky && colors.white,
    zIndex: sticky && '9',
    border: "none",
    fontSize: "1rem",
    fontWeight: 600,
    paddingTop: "10px !important",
    paddingBottom: "10px !important",
    alignItems: "center",
    padding: "0 16px",
    color: colors.buttonActionColor,
    '& .MuiButtonBase-root': {
      position: 'unset',
    },
    "&:hover": {
      color: colors.buttonActionColor,
      opacity: 0.9
    },
    [desktop]: {
      fontSize: "0.75rem !important",
    },
  }
})

export const ContainerSpeedDial = styled('div')(() => {
  return {
    display: 'none',
    width: 'auto',
    height: 'auto',
    [mobile]: {
      display: 'block',
    }
  }
})

export const BaseAutocomplete = styled(Autocomplete)(() => {
  const { colors } = useColors();
  return {
    '.Mui-disabled': {
      background: colors.disabledInput
    },
  }
})

export const GlobalStyle = () => {
  const colors = useColors();

  return (
    <React.Fragment>
      <GlobalStyles colors={colors.colors} />
    </React.Fragment>
  );
};
