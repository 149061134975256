import React, { useRef, useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { UserApi } from '../../../../services/UserApi';

import { PasswordRecoveryForm } from '.'
import Router from '../../../../router';
import { useTranslation } from 'react-i18next'

export const PasswordRecoveryFormContainer = (colors) => {

    const { t } = useTranslation();
    const location = useLocation()
    const userApi = new UserApi();

    const recaptchaRef = useRef();

    const refData = useRef(false)
    const [form, setForm] = useState({
        username: '',
        reCaptchaToken: null
    })
    const [messageResponse, setMessageResponse] = React.useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)

    const handleChange = (event) => {
        setForm({ ...form, [event.target.name]: event.target.value })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setMessageResponse('')
        if (form.username === null || form.username === undefined) {
            setMessageResponse(t('password.validations.mandatory'))
            return;
        }
        if (form.reCaptchaToken === null || form.reCaptchaToken === undefined) {
            setMessageResponse(t('common.mandatory', { field: t('common.recaptcha') }))
            return;
        }

        setIsLoading(true)
        const Url = process.env.REACT_APP_URL + Router.appPasswordReset + '?token='
        userApi.forgotPassword(form, Url).then((resp) => {
            setIsLoading(false)
            if (resp?.status === 200) {
                setMessageResponse('')
                setIsSuccess(true)
                resetCaptcha()
            } else {
                setMessageResponse(resp?.response?.data?.description || resp?.response?.data?.data?.description || t('system.errorSaving'))
                setIsSuccess(false)
                resetCaptcha()
            }
        })
    }

    const resetCaptcha = () => {
        recaptchaRef.current?.reset();
        setForm({ ...form, reCaptchaToken: null })
    }

    useEffect(() => {
        if (refData.current === false) {
            const info = location.state
            setForm({ ...form, username: info?.user == null ? '' : info.user })
            refData.current = true
        }
    }, [location.state, form])

    return (
        <PasswordRecoveryForm
            form={form}
            colors={colors?.colors}
            setForm={setForm}
            isLoading={isLoading}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            messageResponse={messageResponse}
            isSuccess={isSuccess}
            recaptchaRef={recaptchaRef}
        />
    )
}