import React, { useRef, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { UserApi } from '../../../../services/UserApi';

import { PasswordResetForm } from "."

import { useTranslation } from 'react-i18next'

function useQuery(){
    return new URLSearchParams(useLocation().search)
}

export const PasswordResetFormContainer = (colors) => {

    const { t } = useTranslation();
    const location = useQuery()
    const userApi = new UserApi();

    const recaptchaRef = useRef();
    const [form, setForm] = useState({
        password: '',
        confirmPassword: '',
        reCaptchaToken: null
    })
    const [isLoading, setIsLoading] = useState(false)
    const [messageResponse, setMessageResponse] = useState('')
    const [isRedirect, setIsRedirect] = useState(false)

    const getData = () => {
        const data = {...form}
        data.newPassword = data.password
        data.confirmNewPassword = data.confirmPassword
        data.token = location.get('token')

        return data
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        if (form.password === '' || form.confirmPassword === '') {
            setMessageResponse(t('password.validations.newPasswordConfirm'))
            return;
        }
		if (form.password !== form.confirmPassword) {
            setMessageResponse(t('password.validations.matchPasswords'))
            return;
		}
        if (form.reCaptchaToken === null || form.reCaptchaToken === undefined) {
            setMessageResponse(t('common.mandatory', {field: t('common.recaptcha')}))
            return;
		}
        
        setMessageResponse('')
        setIsLoading(true)
        const data = getData()
        userApi.resetPassword(data).then((resp) => {
            setIsLoading(false)
            if (resp?.status === 204) {
                setIsRedirect(true)
                setMessageResponse(t('password.messages.updatePassword.title'))
                resetCaptcha()
            } else {
                setMessageResponse(resp?.response?.data?.description || resp?.response?.data?.data?.description || t('system.errorSaving'))
                setIsRedirect(false)
                resetCaptcha()
            }
        })
    }

    const handleChange = (event) => {
        setForm({ ...form, [event.target.name]: event.target.value })
    }

    const resetCaptcha = () => {
        recaptchaRef.current?.reset();
        setForm({ ...form, reCaptchaToken: null })
    }

    useEffect(() => {
        let token = location.get('token')
        if (token === null || token === undefined) {
            setMessageResponse(t('password.validations.unknownToken'))
            setIsRedirect(true)
        }
    }, [location])

    return (
        <PasswordResetForm
            colors={colors?.colors}
            form={form}
            setForm={setForm}
            isLoading={isLoading}
            isRedirect={isRedirect}
            handleSubmit={handleSubmit}
            handleChange={handleChange}
            messageResponse={messageResponse}
            recaptchaRef={recaptchaRef}
        />
    )
}