import React, { Fragment } from 'react';

// Components
import { Grid, Tooltip, Checkbox, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { SelectInput } from '../../../SelectInput';
import { SelectAll } from '../../../SelectAll';

// Styles
import { Delete } from '@mui/icons-material';
import { CustomGrid, RemoveIconButton, StickyHeader, CustomControlLabel } from './styles';
import { FormButton } from '../../../../pages/styles';

// Utils
import { useTranslation } from 'react-i18next'
import { ColorsContext } from "../../../../Context/ColorsContext"

export const ContractScope = ({
    data,
    errors,
    contractList,
    handleChange,
    handleAddRow,
    handleRemoveRow,
    disabled,
    showCheckSupervisor
}) => {

    const { t } = useTranslation();
    const { colors } = React.useContext(ColorsContext);

    function returnSelectedData(arraySelected, list) {
		// Logic for MUI Autocomplete component bug
		// Data of autocomplete multiple must be taken directly from options array
        let setlData = []
        if (!list) {
            return []
        }
        arraySelected?.map((el) => {
            let objData = list?.filter((obj) => obj.id === el.id)
            setlData.push(...objData)
        })
        return setlData
    }

    return (
        <>
            <CustomGrid container sx={{ maxHeight: '210px', overflowY: 'auto' }}>
                <StickyHeader item xs={showCheckSupervisor ? 2.5 : 3}>{t('contract.table.code')}</StickyHeader>
                <StickyHeader item xs={showCheckSupervisor ? 7 : 7.5}>{t('contract.table.scope')}</StickyHeader>
                {showCheckSupervisor && <StickyHeader item xs={1.5}>{t('contract.table.supervisor')}</StickyHeader>}
                <StickyHeader item xs={showCheckSupervisor ? 1 : 1.5}>{t('common.actions.delete')}</StickyHeader>
                {data?.map((row, index) => (
                    <Fragment key={`contract-${index}`}>
                        <Grid item xs={showCheckSupervisor ? 2.5 : 3}>
                            <SelectInput
                                placeholder={t('contract.table.code')}
                                onChange={(value) => handleChange('contract', value, index)}
                                value={row.contract}
                                options={contractList}
                                keyValue="code"
                                sx={{ width: '25%', fontSize: 10 }}
                                error={errors?.['contractPlantsAssigned']?.[index]?.errorEmptyContract}
                                disabled={disabled}
                            />
                        </Grid>
                        <Grid item xs={showCheckSupervisor ? 7 : 7.5}>
                            <SelectAll
                                placeholder={t('contract.table.scope')}
                                onChange={(value) => handleChange('plants', value, index)}
                                value={returnSelectedData(row.selectedPlants, row.plants)}
                                options={row.plants || []}
                                keyValue="name"
                                sx={{ width: '25%' }}
                                multiple={true}
                                hasError={errors?.['contractPlantsAssigned']?.[index]?.hasEmptyPlants}
                                messageError={errors?.['contractPlantsAssigned']?.[index]?.errorEmptyPlants}
                                disabled={!row.contract?.id}
                            />
                        </Grid>
                        {showCheckSupervisor && 
                            <Grid item xs={1.5}>
                                <CustomControlLabel
                                    control={
                                        <RadioGroup
                                            value={row.userType}
                                            defaultValue={2}
                                            name="radio-buttons-group"
                                            sx={{
                                                color: colors.grayGraphic,
                                            }}
                                            onChange={(e) => handleChange('userType', e.target.value, index)}
                                        >
                                            <FormControlLabel 
                                                value="1" 
                                                control={
                                                    <Radio 
                                                        sx={{
                                                            color: colors.grayGraphic,
                                                            margin: 0,
                                                            '&.Mui-checked': {
                                                                color: colors.buttonActionColor
                                                            }
                                                        }}
                                                    />
                                                } 
                                                label={t('contract.table.optYes')}
                                            />
                                            <FormControlLabel 
                                                value="2" 
                                                control={
                                                    <Radio 
                                                        sx={{
                                                            color: colors.grayGraphic,
                                                            margin: 0,
                                                            '&.Mui-checked': {
                                                                color: colors.buttonActionColor
                                                            }
                                                        }}
                                                    />
                                                } 
                                                label={t('contract.table.optNo')}
                                            />
                                        </RadioGroup>
                                    } 
                                />
                            </Grid>
                        }
                        <Grid item xs={showCheckSupervisor ? 1 : 1.5}>
                            <Grid container display={'flex'} flexDirection={'row'} justifyContent={'center'} alignItems={'center'} sx={{ height: '100%' }}>
                                <Grid item xs={12} sx={{ display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center' }}>
                                    <Tooltip title={t('contract.remove')}>
                                        <RemoveIconButton
                                            size="large"
                                            onClick={() => handleRemoveRow(index)}
                                            disabled={disabled}
                                        >
                                            <Delete fontSize='34px'/>
                                        </RemoveIconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Fragment>
                ))}
            </CustomGrid>

            <Grid container mt={2}>
                <FormButton variant="contained" onClick={handleAddRow} disabled={disabled}>
                    {t('contract.addAnother')}
                </FormButton>
            </Grid>
        </>
    )
}