import {styled} from "@mui/material/styles";
import { CardContent, Grid, Typography, Card } from '@mui/material';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { useColors } from "../../../Context/ColorsContext"
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import { MediaQuerys } from "../../../styles/GlobalStyle";

const { mobile, tablet, desktop } = MediaQuerys;

export const Title = styled(Typography)(() => {
    const { colors } = useColors();
    return {
        color: colors.grayFont,
        fontSize: 12,
        lineHeight: 1.2,
        [desktop]: {
            fontSize: '0.75rem',
        }
    }
})

export const Content = styled(CardContent)(() => ({
    padding: '10px 10px 6px 15px'
}))

export const IconArea = styled('div')(() => {
    const { colors } = useColors();
    return {
        background: colors.orange,
        color: colors.white,
        width: 26,
        height: 26,
        fontSize: 16,
        fontWeight: 'bold',
        borderRadius: 20,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [desktop]: {
            width: '15px !important',
            height: '15px !important',
            fontSize: '11px !important',
        }
    }
})

export const ContentTitle = styled(Grid)(() => ({
    padding: '4px 4px 4px 15px'
}))

export const ListAlt = styled(ListAltIcon)(() => {
    const { colors } = useColors();
    return {
        color: colors.grayFont,
        fontSize: 14
    }
})

export const GridContainer = styled(Grid)(() => {
    const { colors } = useColors();
    return {
        padding: '4px 10px 0px 10px',
        color: colors.grayFont
    }
})

export const ArrowLeft = styled(KeyboardArrowLeft)(({disabled}) => {
    const { colors } = useColors();
    return {
        color: colors.white,
        background: disabled ? colors.grayFont : colors.main,
        borderRadius: 20,
        cursor: disabled ? 'default' : 'pointer'
    }
})

export const CustomCard = styled(Card)(() => {
    return {
        [mobile]: {
           minWidth: 'max-content',
        }
    }
})
  