import React, { useEffect, useCallback } from "react";
import { Button, Modal } from "@mui/material";
import { CloudDownloadOutlined } from "@mui/icons-material";
import { toast } from 'react-toastify';

import { PageTitle } from "../../components/PageTitle";
import TableUsers from "../../components/atoms/tables/TableUsers";
import { UserModalCard } from "../../components/atoms/modalCards/UserModalCard";
import { BackdropLoading } from "../../components/BackdropLoading";
import { ShowDialog } from '../../components/ShowDialog';

// Services
import { UserApi } from "../../services/UserApi";

import { Container, ContainerBarSearch, ContainerButtonDownload, SectionPageLeft, SectionPageTitle, ContainersButtonsGroup } from "../styles";
import { BoxModal, ButtonForm, ContainerButtonCreate } from "./styles";

import PeopleIcon from "@mui/icons-material/People";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { SearchBarUsers } from "../../components/atoms/searchBars/SearchBarUsers";
import { useTranslation } from "react-i18next";
import { ColorsContext } from "../../Context/ColorsContext"
import { CheckResponse } from "../../utils/checkResponse";
import { ExportExcel } from "../../utils/exportExcel";

// Permissions
import { useAbility } from '@casl/react';
import { AbilityContext } from "../../Context/PermissionsContext";

export const UserList = (props) => {
  const { t } = useTranslation();
  const { colors } = React.useContext(ColorsContext);
  const ability = useAbility(AbilityContext);

  const userApi = new UserApi();

  const { check } = new CheckResponse();

  const refData = React.useRef(false);
  const actionRef = React.useRef("CREATE");
  const authTypeRef = React.useRef(true);
  const [title, setTitle] = React.useState(t("system.users.createUser"));

  const [filteredRequest, setFilteredRequest] = React.useState([]);
  const [openModalUser, setOpenModalUser] = React.useState(false);

  const [loadingData, setLoadingData] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [selectedData, setSelectedData] = React.useState({})
  const searchText = React.useRef("");
  const pageRef = React.useRef({
    page: 0,
    pageSize: 10,
    rowsCount: 0,
    fullRowsCount: 0
  });
  const firstLoad = React.useRef(true);

  const [dialog, setDialog] = React.useState({
    title: '',
    message: '',
    status: false
  });

  const onDownload = async() => {
    setLoading(true)
    let response = await userApi.download(searchText.current);
    let result = check(response)

    if (result.status) {
      ExportExcel({ apiData: response.data, fileName: t('system.users.title'), isBlob: true });
    }
    else {
      toast.error(() => 
        <div>{t('common.errors.download')}<br/>
          {result.errors}
        </div>
      );
    }
    setLoading(false)
  }

  const loadData = async () => {
    setLoadingData(true)
    let response = await userApi.paginated({
      pageSize: pageRef.current.pageSize, 
      currentPage: pageRef.current.page + 1, 
      filter: searchText.current
    });
    if (response.status === 200) {
      let data = response.data;
      pageRef.current.rowsCount = data.rowsCount;
      pageRef.current.fullRowsCount = data.rowsCount;
      setConsecutiveId(data.results, pageRef.current.page, pageRef.current.pageSize);
      chunkData(data)
      setLoadingData(false)
      firstLoad.current = false;
    }
    setLoadingData(false)
  }

  const setConsecutiveId = (array, page, pageSize) => {
    // Se detectó un bug con respecto al consecutivo del id de la variable data
    // por lo cual, se asigna un id consecutivo y el id original se almacena en idKey
    array.map((el, index) => {
      el.idKey = el.id;
      el.id = (index + 1) + (page * pageSize);
    })
    return array
  }

  const chunk = (arr, size) =>
    Array.from({ length: Math.ceil(arr.length / size) }, (_, i) =>
      arr.slice(i * size, i * size + size)
  );

  const chunkData = (currentData) => {
    let mergedData = currentData.results;
    let arrayData = [];

    if (data.length === 0 || firstLoad.current) {
      arrayData = mergedData;
    }
    else {
      // Dividir filteredRequest en bloques de datos de pageSize
      let newArray = chunk(data, pageRef.current.pageSize);
      // Validar si la data de currentpage ya existe en filteredRequest
      // Si no existe se agrega la nueva data a filteredRequest
      if (newArray[currentData.currentPage - 1] === undefined) {
        arrayData = [...data, ...mergedData];
      }
      // Si ya existe se busca la posición y se reemplaza con la nueva data
      else {
        newArray[currentData.currentPage - 1] = mergedData;
        arrayData = [].concat(...newArray);
      }
    }

    setData(arrayData);
    setFilteredRequest(arrayData);
    return arrayData;
  }

  const handleChangeFilter = async() => {
    pageRef.current.page = 0;
    firstLoad.current = true;
    await loadData();
  };
  
  const handleChangePage = async(page, pageSize, changePageSize) => {
    pageRef.current.page = page;
    pageRef.current.pageSize = pageSize || 10;
    if (changePageSize) firstLoad.current = true;
    await loadData();
  };

  function handleCloseDialog(result) {
    setDialog({ ...dialog, status: false })

    if (result && (actionRef.current === 'ACTIVATE' || actionRef.current === 'DEACTIVATE')) {
      handleToggle()
    }
  }

  const handleOpen = () => {
    setTitle(t("system.users.createUser"));
    actionRef.current = "CREATE";
    setOpenModalUser(true);
    authTypeRef.current = false;
  };

  const handleCloseModalUser = () => {
    setSelectedData({})
    setOpenModalUser(false);
  };

  const handleRemove = (item, action) => {
    selectedUser(item);

    actionRef.current = action;
    setDialog({
      status: true,
      title: t('system.users.user'),
      message: t('system.users.confirmChangeAccount', { state: action === 'DEACTIVATE' ? 'system.users.disable' : 'system.users.enable', username: item.username }),
      type: 'confirmation'
    })
  };

  const handleToggle = () => {
    setLoading(true);
    let data = {
      ...selectedData,
      isEnabled: !selectedData.isEnabled,
      role: null,
      authenticationType: selectedData.authenticationType
        ? "ACTIVE_DIRECTORY"
        : "LOCAL_AUTHENTICATION",
    };
    userApi.toggleAccount(data.id, data).then((resp) => {
      if (resp?.status === 204) {
        loadData();
      } else {
        toast.error(() =>
          <div>{t('system.users.messages.updatedData.error')}<br />
            {resp?.data?.description || resp?.data?.data?.description || resp?.response?.data?.description || resp?.response?.data?.data?.description || t('system.errorSaving')}
          </div>
        );
      }
      setLoading(false);
    });
  };

  function formatContractPlants(array) {
    if (!array?.length) return false
    array.map((row) => {
      row.selectedPlants = returnSelectedData(row.plants, row.contract.plants)
      row.selectedPlants = row.selectedPlants.map((item) => { return { ...item, disabled: true } })
      row.plantsOfContract = row.contract.plants
      row.plants = row.contract.plants
      row.userType = row.userType
    })
    return array
  }

  const selectedUser = async (item, type = "") => {
    actionRef.current = "UPDATE";
    setTitle(t("system.users.editUser"));
    let contractsPlants = formatContractPlants(item.contractPlants) || emptyRow()
    let formData = {
      id: item.idKey,
      username: item.username,
      name: item.name,
      lastName: item.lastName,
      identification: item.identification,
      lastLogin: item.lastLogin,
      email: item.email,
      role: item.role,
      isEnabled: item.isEnabled,
      password: "",
      confirm_password: "",
      plants: item.plants || [],
      contract: item.contract,
      contractId: item.contract?.id,
      authenticationType: item.authenticationType === "ACTIVE_DIRECTORY",
      contractPlantsUser: [],
      contractPlantsAssigned: contractsPlants,
      company: item.company,
      isContractor: item.isContractor || false,
      userType: item.userType
    }
    setSelectedData(formData)
    authTypeRef.current = item.authenticationType === "ACTIVE_DIRECTORY";

    if (type === "UPDATE") setOpenModalUser(true);
  };

  function returnSelectedData(arraySelected, list) {
    // Logic for MUI Autocomplete component bug
    // Data of autocomplete multiple must be taken directly from options array
    let setlData = []
    if (!list) return []
    arraySelected?.map((el) => {
      let objData = list?.filter((obj) => obj.id === el.id)
      setlData.push(...objData)
    })
    return setlData
  }

  const emptyRow = () => {
    return [
      {
        contract: {
          code: ''
        },
        plants: [],
        userType: 2
      }
    ]
  }

  useEffect(() => {
    if (refData.current === false) {
      loadData();
      refData.current = true;
    }
  }, [loadData, openModalUser]);

  useEffect(() => {
    const listener = async(event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        await handleChangeFilter();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  return (
    <>
      <Container open={props.open}>
        <BackdropLoading open={loading} />
        <ShowDialog openDialog={dialog.status} dialog={dialog} handleCloseDialog={handleCloseDialog} />

        <SectionPageTitle>
          <SectionPageLeft>
            <PageTitle title={t("system.users.title")} icon={<PeopleIcon />} />
            <ContainerBarSearch>
              <SearchBarUsers rows={data} setFilteredRequest={setFilteredRequest} searchText={searchText} handleChangeFilter={handleChangeFilter}/>
            </ContainerBarSearch>
          </SectionPageLeft>
          <ContainersButtonsGroup twoContainers={true}>
            {ability.can('UsersDownload') &&
              <ContainerButtonDownload>
                <ButtonForm
                  variant="contained"
                  endIcon={<CloudDownloadOutlined />}
                  aria-label="download"
                  onClick={onDownload}
                >
                  {t('common.download')}
                </ButtonForm>
              </ContainerButtonDownload>
            }

            {ability.can('SignUp') &&
              <ContainerButtonCreate>
                <ButtonForm
                  variant="contained"
                  endIcon={<PersonAddIcon />}
                  aria-label="add-user"
                  onClick={handleOpen}
                >
                  {t("system.users.createUser")}
                </ButtonForm>
              </ContainerButtonCreate>
            }

          </ContainersButtonsGroup>
        </SectionPageTitle>

        <TableUsers
          filteredRequest={filteredRequest}
          rows={data}
          selectedUser={selectedUser}
          handleRemove={handleRemove}
          loadingData={loadingData}
          onChangePage={handleChangePage}
          rowsCount={pageRef.current.rowsCount}
          currentPage={pageRef.current.page}
        />
      </Container>
      <Modal open={openModalUser}>
        <BoxModal>
          <UserModalCard
            title={title}
            setDialog={setDialog}
            selectedData={selectedData}
            action={actionRef.current}
            authTypeRef={authTypeRef}
            emptyRow={emptyRow}
            handleCloseModalUser={handleCloseModalUser}
            returnSelectedData={returnSelectedData}
            loadData={loadData}
            setOpenModalUser={setOpenModalUser}
          ></UserModalCard>
        </BoxModal>
      </Modal>
    </>
  );
};
