import { Auth } from "../utils/auth";
import { HealthCheckApi } from "./HealthCheckApi";
import Router from "../router";
import axios from "./AxiosConfig";

export class CompanyWorkerApi {
    healthCheck = null;
    token = null;

    constructor() {
        let auth = new Auth();
        this.token = auth.getToken();
        this.healthCheck = new HealthCheckApi();
    }

    async list({isEnabled, currentPage, pageSize, filter}) {
        return await axios.get(Router.apiBaseUrl + Router.apiCompanyWorker + `/paginated`, {
            params: {
                isEnabled: isEnabled,
                currentPage: 1,
                currentPage, 
                pageSize,
                filter
            }
        })
        .then(async response => {
            return await this.healthCheck.checkApi(response)
        }).catch(async error => {
            return await this.healthCheck.checkApi(error)
        })
    }

    async create(data) {
        return await axios.post(Router.apiBaseUrl + Router.apiCompanyWorker, data)
        .then(async response => {
            return await this.healthCheck.checkApi(response)
        }).catch(async error => {
            return await this.healthCheck.checkApi(error)
        });
    }

    async update(id, data) {
        return await axios.put(Router.apiBaseUrl + Router.apiCompanyWorker + `/${id}`, data)
        .then(async response => {
            return await this.healthCheck.checkApi(response)
        }).catch(async error => {
            return await this.healthCheck.checkApi(error)
        })
    }

    async delete(id) {
        return await axios.delete(Router.apiBaseUrl + Router.apiCompanyWorker + `/${id}`, {})
        .then(async response => {
            return await this.healthCheck.checkApi(response)
        }).catch(async error => {
            return await this.healthCheck.checkApi(error)
        })
    }

    async upload(data) {
        return await axios.post(Router.apiBaseUrl + Router.apiCompanyWorker + `/upload`, data)
        .then(async response => {
            return await this.healthCheck.checkApi(response)
        }).catch(async error => {
            return await this.healthCheck.checkApi(error)
        });
    }

    async template() {
        return await axios.get(Router.apiBaseUrl + Router.apiCompanyWorker + `/template`, { responseType: 'blob' })
        .then(async response => {
            return await this.healthCheck.checkApi(response)
        }).catch(async error => {
            return await this.healthCheck.checkApi(error)
        })
    }

}