import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import { FormControlLabel } from '@mui/material';
import { useColors } from "../../../../Context/ColorsContext";
import { MediaQuerys } from "../../../../styles/GlobalStyle";
import { Button } from '@mui/material';

const { mobile, tablet, desktop } = MediaQuerys;

export const Container = styled('div')(({theme}) => ({
  display: 'flex',
  width: '100%',
  height: '100%',
  // minwidth: '400px',
  flexDirection: 'column',
  [theme.breakpoints.down('lg')]: {
    minWidth: '600px !important',
  },
  [theme.breakpoints.down('sm')]: {
    minWidth: '400px !important',
  }
}))

export const Header = styled('div')(() => {
  const { colors } = useColors();
  return {
    display: 'flex',
    background: colors.white,
    color: colors.detailTextColor,
    width: '100%',
    height: 'fit-content',
    borderRadius: '10px 10px 0 0',
    padding: '16px',
    textTransform: 'uppercase',
    fontWeight: 600,
    [desktop]: {
      fontSize: '0.75rem',
    }
  }
})

export const Body = styled('div')(({ isStandardFlow }) => {
  const { colors } = useColors();
  return {
    display: 'flex',
    flexDirection: 'column',
    // flex: '1',
    width: '100%',
    background: colors.white,
    [tablet]: {
      width: isStandardFlow && '550px'
    },
    [mobile]: {
      width: '100%'
    }
  }
})

export const Footer = styled('div')(() => {
  const { colors } = useColors();
  return {
    display: 'flex',
    background: colors.white,
    width: '100%',
    height: 'fit-content',
    borderRadius: '0 0 10px 10px',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '8px 16px',
    margin: 'auto'
  }
})

export const ContainerInfo = styled('div')(({disabled, isStandardFlow }) => {
  const { colors } = useColors();
  return {
    display: 'flex',
    flexDirection:'column',
    background: colors.white,
    width: '100%',
    height: 'fit-content',
  }
})

export const SectionRight = styled('section')(({ content }) => ({
  display: 'flex',
  width: content && '30%',
  flexDirection: content && 'row',
  justifyContent: content ? 'center' : 'flex-end',
  gap: '1rem',
  [mobile]: {
    width: content && '50%'
  }
}))

export const Input = styled(TextField)(({ isMobile }) => {
  const { colors } = useColors();
  return {
    width: '100%',
    height: '100%',
    fontSize: '0.75rem',
    '.MuiInputBase-root': {
      background: colors.white,
      borderRadius: 6,
      fontSize: 13
    },
    [desktop]: {
      '.MuiInputBase-root': {
        background: colors.white,
        borderRadius: 6,
        fontSize: '0.85rem'
      },
      '.MuiInputLabel-root': {
        fontSize: '0.85rem',
      },
    },
    [mobile]: {
      width: isMobile && '33%'
    }
  }
})

export const ContainerContentModal = styled('div')(({ isStandardFlow }) => {
  return {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    flexWrap: 'wrap',
    padding: isStandardFlow && '16px',
  }
})


export const ContainerContentUp = styled('div')(() => {
  return {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
    gap: '0.75rem',
    marginTop: '16px',
  }
})

export const ContainerContentDown = styled('div')(() => {
  return {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    marginTop: '16px',
    marginBottom: '16px',
    gap: '1rem',
    flexDirection: 'column',
    // [tablet]: {
    //   flexDirection: 'column'
    // }

  }
})


export const ContainerInput = styled('div')(({ width }) => {
  return {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '50px',
    width: width || '40%',
  }
})


export const Label = styled('label')(() => {
  return {
    fontSize: '0.875em',
    [desktop]: {
      fontSize: '0.85rem',
    }
  }
})


export const ContainerInputColor = styled('div')(() => {
  return {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    gap: '0.75rem',
    width: '33%',
  }
})


export const ContainerIcon = styled('div')(() => {
  return {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
  }
})


export const ContainerInputGroup = styled('div')(() => {
  return {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-end',
    width: '33%',
    gap: '1rem',
  }
})


export const CustomButton = styled(Button)(() => {
  const { colors } = useColors();
  return {
    height: 28, 
    padding: '0 20px', 
    color: colors.white,
    backgroundColor: colors.buttonActionColor,
    "&:hover": {
        backgroundColor: colors.gray,
        color: colors.buttonActionColor,
    },
    [desktop]: {
      height: 25,
      fontSize: '0.80rem',
    }
  }
})

export const CustomFormControl = styled(FormControlLabel)(() => {
  return {
    '& .MuiFormControlLabel-root': {
      justifyContent: 'center',
    },
    [desktop]: {
      '.MuiTypography-root': {
        fontSize: '0.85rem'
      }
    },
    [tablet]: {
      '.MuiTypography-root': {
        fontSize: '0.75rem'
      }
    }
  }
})

export const ContainerContentColor = styled('div')(() => {
  return {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'flex-start',
    gap: '5px',
  }
})

export const SectionLeft = styled('section')(() => {
  return {
    display: 'flex',
    flexDirection: 'row',
    gap: '0.75rem',
    width: '70%',
    [mobile]: {
      width: '100%',
    }
  }
})

export const ContainerGroup = styled('div')(() => {
  return {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    gap: '0.75rem',
    [mobile]: {
      flexDirection: 'column',
      gap: '0.75rem',
    }
  }
})

export const ContainerRol = styled('div')(() => {
  return {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-start',
    gap: '1rem'
  }
})

export const ContainerBtnSearch = styled('div')(() => {
  return {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
  }
})

export const ContainerSelectInput = styled('div')(() => {
  return {
    width: '40%',
    [mobile]: {
      width: '50%',
    }
  }
})
