import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { StepOne } from '../forms/UserStepper/StepOne';
import { StepTwo } from '../forms/UserStepper/StepTwo';
import { StepThree } from '../forms/UserStepper/StepThree';
import { CustomButton, Wizard } from './styles';
import { AbilityContext } from '../../../Context/PermissionsContext';
import { useAbility } from '@casl/react';

export default function CustomStepper({
  steps, 
  nextValidation,
  form,
  data,
  roleList,
  selectedRole,
  selectedPlants,
  errors,
  setErrors,
  loading,
  authTypeRef,
  handleChangeStepOne,
  handleChangeStepTwo,
  handleSetRole,
  handleAuthentication,
  handleValidateEmail,
  validateEmail,
  buttonRef,
  handleGeneratePassword,
  showPasswordGenerator,
  setShowPasswordGenerator,
  handleGenerateClick,
  roleType,
  categories,
  plants,
  companies,
  handleFilesDrop,
  handleSetCompany,
  handleSetPlants,
  selectedCompany,
  companyContracts,
  setForm,
  user,
  isSupervisor,
  passwordPolicyData,
  showCheckSupervisor
}) {
	const { t } = useTranslation();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const ability = useAbility(AbilityContext);

  const isStepOptional = (step) => {
    // return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = async() => {
    const nextStepValidation = await nextValidation(activeStep);
    if (nextStepValidation) {
      let newSkipped = skipped;
      if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values());
        newSkipped.delete(activeStep);
      }
  
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Box sx={{ width: '100%' }}>
      {activeStep === steps.length ? (
        <React.Fragment>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div sx={{ mt: 2, mb: 1 }}>
            {activeStep === 0 &&
              <StepOne 
                form={form}
                roleList={roleList}
                selectedRole={selectedRole}
                errors={errors}
                loading={loading}
                authTypeRef={authTypeRef}
                handleChange={handleChangeStepOne}
                handleSetRole={handleSetRole}
                handleAuthentication={handleAuthentication}
                handleValidateEmail={handleValidateEmail}
                validateEmail={validateEmail}
                buttonRef={buttonRef}
                handleGeneratePassword={handleGeneratePassword}
                showPasswordGenerator={showPasswordGenerator}
                setShowPasswordGenerator={setShowPasswordGenerator}
                handleGenerateClick={handleGenerateClick}
                setForm={setForm}
                passwordPolicyData={passwordPolicyData}
              />
            } 
            {activeStep === 1 &&
              <StepTwo 
                data={data}
                roleType={roleType}
                handleChangeStepTwo={handleChangeStepTwo}
                form={form}
                setForm={setForm}
                categories={categories}
                plants={plants}
                companies={companies}
                handleSetCompany={handleSetCompany}
                handleSetPlants={handleSetPlants}
                selectedCompany={selectedCompany}
                selectedPlants={selectedPlants}
                companyContracts={companyContracts}
                errors={errors}
                setErrors={setErrors}
                user={user}
                isSupervisor={isSupervisor}
                showCheckSupervisor={showCheckSupervisor}
              />
            }
            {(activeStep === 2 && ability.can("UserAttachmentCreate")) &&
              <StepThree
                data={data}
                validateEmail={validateEmail}
                handleFilesDrop={handleFilesDrop}
                setForm={setForm}
              />
            }
          </div>
        </React.Fragment>
      )}

      <Wizard activeStep={activeStep}>
        {steps?.map((row, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepOptional(index)) {
            labelProps.optional = (
              <Typography variant="caption">Optional</Typography>
            );
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={row} {...stepProps}>
              <StepLabel {...labelProps}>{row}</StepLabel>
            </Step>
          );
        })}
      </Wizard>

      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
        <CustomButton
          disabled={activeStep === 0}
          onClick={handleBack}
          type="back"
        >
          {t('common.stepper.back')}
        </CustomButton>

        <Box sx={{ flex: '1 1 auto' }} />
        {isStepOptional(activeStep) && (
          <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
            Skip
          </Button>
        )}
        
        <CustomButton
          onClick={handleNext}
          type="next"
        >
          {activeStep === steps.length - 1 ? t('common.stepper.finished') : t('common.stepper.next')}
        </CustomButton>
      </Box>
    </Box>
  );
}
